import React from 'react'
import { Col, Row, Table } from 'react-bootstrap'

const TaxInformationTab = ({ hotelDetails, property_id }) => {
  return (
    <div>
      <Row>
        <Col xs={6} xl={6} className="mb-3">
          <div className="information-division">
            <h3 className="inner-header m-0"> Meeting Rooms Tax/Fees</h3>
            <div className="tab-inner-box">
              <div className="customtablemetting">
                <Table className="table-without-space mb-0" responsive bordered>
                  <thead>
                    <tr>
                      <th style={{ width: "70%" }} className="settitle">
                        Tax
                      </th>
                      <th style={{ width: "30%" }} className="settitle">
                        Value
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {hotelDetails.propertiesRoomMeetingTaxes.length ? (
                      hotelDetails.propertiesRoomMeetingTaxes.map(
                        (x1, i) =>
                          x1.IsApply && (
                            <>
                              <tr key={i}>
                                <td>{x1.TaxTitle}</td>
                                <td className="bordernone" style={{ textAlign: "center" }}>
                                  {x1.RateOrPercentage && x1.RateOrPercentage > 0
                                    ? x1.IsPercentage == true
                                      ? x1.RateOrPercentage + "%"
                                      : "$" + x1.RateOrPercentage
                                    : 0}
                                </td>
                              </tr>
                            </>
                          )
                      )
                    ) : (
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan={2}>
                          No data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>

              </div>
            </div>
          </div>
        </Col>
        <Col xs={6} xl={6} className="mb-3">
          <div className="information-division">
            <h3 className="inner-header m-0"> Guest Rooms Tax/Fees</h3>
            <div className="tab-inner-box">
              <div className="customtablemetting">

                <Table className="table-without-space mb-0" responsive bordered>
                  <thead>
                    <tr>
                      <th style={{ width: "70%" }} className="settitle">
                        Tax
                      </th>
                      <th style={{ width: "30%" }} className="settitle">
                        Value
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {hotelDetails.propertiesRoomTypeTaxes.length ? (
                      hotelDetails.propertiesRoomTypeTaxes.map(
                        (y, i) =>
                          y.IsApply && (
                            <>
                              <tr key={i}>
                                <td>{y.TaxTitle}</td>
                                <td className="bordernone" style={{ textAlign: "center" }}>
                                  {y.RateOrPercentage && y.RateOrPercentage > 0
                                    ? y.IsPercentage == true
                                      ? y.RateOrPercentage + "%"
                                      : "$" + y.RateOrPercentage
                                    : 0}
                                </td>
                              </tr>
                            </>
                          )
                      )
                    ) : (
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan={2}>
                          No data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={6} xl={6} className="mb-3">
          <div className="information-division">
            <h3 className="inner-header m-0"> Food Tax/Fees</h3>
            <div className="tab-inner-box">
              <div className="customtablemetting">
                <Table className="table-without-space mb-0" responsive bordered>
                  <thead>
                    <tr>
                      <th style={{ width: "70%" }} className="settitle">
                        Tax
                      </th>
                      <th style={{ width: "30%" }} className="settitle">
                        Value
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {hotelDetails.foodTaxes.length ? (
                      hotelDetails.foodTaxes.map(
                        (x1, i) =>
                          x1.IsApply && (
                            <>
                              <tr key={i}>
                                <td>{x1.TaxTitle}</td>
                                <td className="bordernone" style={{ textAlign: "center" }}>
                                  {x1.RateOrPercentage && x1.RateOrPercentage > 0
                                    ? x1.IsPercentage == true
                                      ? x1.RateOrPercentage + "%"
                                      : "$" + x1.RateOrPercentage
                                    : 0}
                                </td>
                              </tr>
                            </>
                          )
                      )
                    ) : (
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan={2}>
                          No data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>

              </div>
            </div>
          </div>
        </Col>
        <Col xs={6} xl={6} className="mb-3">
          <div className="information-division">
            <h3 className="inner-header m-0"> Beverage Tax/Fees</h3>
            <div className="tab-inner-box">
              <div className="customtablemetting">

                <Table className="table-without-space mb-0" responsive bordered>
                  <thead>
                    <tr>
                      <th style={{ width: "70%" }} className="settitle">
                        Tax
                      </th>
                      <th style={{ width: "30%" }} className="settitle">
                        Value
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {hotelDetails.beverageTaxes.length ? (
                      hotelDetails.beverageTaxes.map(
                        (y, i) =>
                          y.IsApply && (
                            <>
                              <tr key={i}>
                                <td>{y.TaxTitle}</td>
                                <td className="bordernone" style={{ textAlign: "center" }}>
                                  {y.RateOrPercentage && y.RateOrPercentage > 0
                                    ? y.IsPercentage == true
                                      ? y.RateOrPercentage + "%"
                                      : "$" + y.RateOrPercentage
                                    : 0}
                                </td>
                              </tr>
                            </>
                          )
                      )
                    ) : (
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan={2}>
                          No data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={6} xl={6} className="mb-3">
          <div className="information-division">
            <h3 className="inner-header m-0"> AV Equipment Tax/Fees</h3>
            <div className="tab-inner-box">
              <div className="customtablemetting">
                <Table className="table-without-space mb-0" responsive bordered>
                  <thead>
                    <tr>
                      <th style={{ width: "70%" }} className="settitle">
                        Tax
                      </th>
                      <th style={{ width: "30%" }} className="settitle">
                        Value
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {hotelDetails.avEquipmentTaxes.length ? (
                      hotelDetails.avEquipmentTaxes.map(
                        (x1, i) =>
                          x1.IsApply && (
                            <>
                              <tr key={i}>
                                <td>{x1.TaxTitle}</td>
                                <td className="bordernone" style={{ textAlign: "center" }}>
                                  {x1.RateOrPercentage && x1.RateOrPercentage > 0
                                    ? x1.IsPercentage == true
                                      ? x1.RateOrPercentage + "%"
                                      : "$" + x1.RateOrPercentage
                                    : 0}
                                </td>
                              </tr>
                            </>
                          )
                      )
                    ) : (
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan={2}>
                          No data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>

              </div>
            </div>
          </div>
        </Col>

      </Row>


    </div>
  )
}

export default TaxInformationTab