import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { fnCheckValidationOfObject, ToasterError, ToasterSuccess } from '../../Service/CommonService';
import { getSuperAdminChangePassword } from '../../Service/LoginService';
import { DisplayText } from '../../../src/Service/CommonService'

const ChangepasswordModal = (props) => {

  const [ChangePassword, setChangePassword]: any = React.useState(
    {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      errors: {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        ValidationRules: [
          {
            FieldName: "currentPassword",
            ValidationType: "required",
            ValidationMessage: "This field is required.",
          },
          {
            FieldName: "currentPassword",
            ValidationType: "isCurrentPassword",
            ValidationMessage: "Please enter current password.",
          },
          {
            FieldName: "currentPassword",
            ValidationType: "range",
            ValidationMessage: "Must be 8 character's long",
          },

          {
            FieldName: "newPassword",
            ValidationType: "required",
            ValidationMessage: "This field is required.",
          },
          {
            FieldName: "newPassword",
            ValidationType: "not-valid",
            ValidationMessage: "New and Confirm password must be same.",
          },
          {
            FieldName: "newPassword",
            ValidationType: "range",
            ValidationMessage: "Must be 8 character's long",
          },

          {
            FieldName: "confirmPassword",
            ValidationType: "required",
            ValidationMessage: "This field is required.",
          },
          {
            FieldName: "confirmPassword",
            ValidationType: "not-valid",
            ValidationMessage: "New and Confirm password must be same.",
          },
          {
            FieldName: "confirmPassword",
            ValidationType: "range",
            ValidationMessage: "Must be 8 character's long",
          },

        ],
      },
    }
  )

  const userID = props.dataSelector.auth_UserDetail.userID;

  async function handleChangePassword(event) {
    event.preventDefault();
    let obj = fnCheckValidationOfObject(ChangePassword);
    setChangePassword({
      ...obj.obj,
    });
    if (obj.isValid) {
      localStorage.removeItem("currentPass");
      localStorage.setItem("currentPass", ChangePassword.newPassword)
      let input = {
        userID: userID,
        password: ChangePassword.newPassword
      }
      let res: any = await getSuperAdminChangePassword(input, props.Token)

      if (res.data !== undefined) {
        let Message = "Password";
        if (res.data.statusCode === 200) {
          ToasterSuccess(Message);
          props.onHide(false);
          setChangePassword(
            {
              currentPassword: "",
              newPassword: "",
              confirmPassword: "",
              errors: {}
            })
        } else {
          ToasterError(res.message);
        }
      }
    }
  }
  return (
    <Modal
      {...props}
      className="clientpoupadd"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row mb-5">
            <div className="col-md-12">
              <Form.Group>
                <Form.Label>Current Password</Form.Label>
                <Form.Control
                  type="text" maxLength={160}
                  placeholder={DisplayText.CurrentPassword}

                  name="currentPassword"
                  value={ChangePassword.currentPassword}
                  onChange={(e) => setChangePassword({
                    ...ChangePassword,
                    ["currentPassword"]: e.target.value
                  })}
                  isInvalid={ChangePassword.errors.currentPassword}
                />
                {ChangePassword.errors.currentPassword && (
                  <Form.Control.Feedback type="invalid">
                    {ChangePassword.errors.currentPassword}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
            <div className="col-md-12">
              <Form.Group>
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder={DisplayText.NewPassword}
                  maxLength={160}
                  name="newPassword"
                  value={ChangePassword.newPassword}
                  onChange={(e) => setChangePassword({
                    ...ChangePassword,
                    ["newPassword"]: e.target.value
                  })}
                  isInvalid={ChangePassword.errors.newPassword}
                />
                {ChangePassword.errors.newPassword && (
                  <Form.Control.Feedback type="invalid">
                    {ChangePassword.errors.newPassword}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
            <div className="col-md-12">
              <Form.Group>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="text" maxLength={160}
                  placeholder={DisplayText.ConfirmPassword}

                  name="confirmPassword"
                  value={ChangePassword.confirmPassword}
                  onChange={(e) => setChangePassword({
                    ...ChangePassword,
                    ["confirmPassword"]: e.target.value
                  })}
                  isInvalid={ChangePassword.errors.confirmPassword}
                />

                {ChangePassword.errors.confirmPassword && (
                  <Form.Control.Feedback type="invalid">
                    {ChangePassword.errors.confirmPassword}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="savebtnclient" onClick={handleChangePassword} >
          <i className="fa-solid fa-floppy-disk me-2"></i>Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangepasswordModal