import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InPageNav from "../Common/InPageNav/Index";
import Select from 'react-select';
import { useSelector } from "react-redux";
import AccountFIleUploadModal from "../Model/AccountFIleUploadModal";
import ContactFileUploadModal from "../Model/ContactFileUploadModal";
import SuspectFileUploadModal from "../Model/SuspectFileUploadModal";
import { CommonService, GetPageRight } from "../../Service/CommonService";


function Imports() {
  let pageRights = GetPageRight('Imports')
  const dataSelector: any = useSelector((state: any) => state.session);
  const ClientDropdown = dataSelector.ClientListDropdown || [];
  let ClientLabel = localStorage.getItem('SelectedClientLabel');
  let ClientID = localStorage.getItem('SelectedClientID');
  const [ValueImport, setValueImport]: any = useState({ key: "" })
  const [modalShowAccountupload, setModalShowAccountupload] = React.useState(false);
  const [modalShowContactupload, setModalShowContactupload] = React.useState(false);
  const [modalShowSuspectupload, setModalShowSuspectupload] = React.useState(false);
  const [FileUploadbtn, setFileUploadbtn] = useState("")
  const [FileUploadbtnClient, setFileUploadbtn1Client]: any = useState("" || ClientLabel)

  const pageNav = [
    {
      name: 'Imports',
      link: '/Imports',
      active: true
    }
  ]
  let options: any = [{
    value: null,
    label: "Select Client",
    color: ""
  }];


  ClientDropdown.map((item: any) => {
    options.push({
      value: item.clientID,
      label: item.clientName,
      color: "",
    });
  });


  async function SelectClientID(event) {
    ;
    if (event.label !== "Select Client") {
      setFileUploadbtn1Client(ClientLabel)
    } else {
      setFileUploadbtn1Client("")
    }

    let selectedClientID = event.value;
    let selectedClientLabel = event.label
    localStorage.setItem("SelectedClientID", selectedClientID);
    localStorage.setItem("SelectedClientLabel", selectedClientLabel);
  }

  function SelectModule(e: any) {

    if (e.target.value !== "Select Module") {
      setFileUploadbtn(e.target.value)
    } else {
      setFileUploadbtn("")
    }

    setValueImport({
      ...ValueImport,
      ["key"]: e.target.value,
    })
  }

  function ImportButton() {
    ValueImport.key
    ClientID
    if (ValueImport.key === "Accounts") {
      setModalShowAccountupload(true)
    }
    if (ValueImport.key === "Contacts") {
      setModalShowContactupload(true)
    }
    if (ValueImport.key === "Suspects") {
      setModalShowSuspectupload(true)
    }

  }

  return (
    <>

      <div className="propertylisting">
        <InPageNav pageNav={pageNav} />
        <div className="student-and-expert d-flex">
          <div className="export-and-client clientpoupadd d-flex">
            <div className="export me-3 selectheader mycusged">
              <Form.Group>
                <Form.Select aria-label="Default select example" onChange={(e) => SelectModule(e)}>
                  <option >Select Module</option>
                  <option value="Accounts">Accounts</option>
                  <option value="Contacts">Contacts</option>
                  <option value="Suspects">Suspects</option>
                  {/* <option value="2">Activities</option> */}
                </Form.Select>
              </Form.Group>

            </div>
            <div className="export me-3 selectheader">
              <Form.Group>
                <Select
                  placeholder="Select Client"
                  className="multiselect" options={options} onChange={SelectClientID}
                  defaultValue={{ label: ClientLabel || "Select Client", value: ClientID }} />

              </Form.Group>
            </div>
            <div className="client">
              <Button className="savebtnclient" onClick={() => {
                if (pageRights.is_create) {
                  ImportButton()
                } else {
                  CommonService.unauthorizedAction()
                }
              }}
                disabled={FileUploadbtn !== "" && FileUploadbtnClient !== "" ? false : true}
              >
                <i className="fa-solid fa-file-arrow-up me-2" ></i>File Upload
              </Button>
            </div>
          </div>
        </div>
        <div className="tabelData">
        </div>
      </div>
      <AccountFIleUploadModal show={modalShowAccountupload} onHide={() => setModalShowAccountupload(false)} />
      <ContactFileUploadModal show={modalShowContactupload} onHide={() => setModalShowContactupload(false)} />
      <SuspectFileUploadModal show={modalShowSuspectupload} onHide={() => setModalShowSuspectupload(false)} />
    </>
  );
}

export default Imports;