import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from "react-redux";
import Select from 'react-select';
import { toast } from 'react-toastify';
import { ReactComponent as ExportIcon } from "../../assets/Images/Icons/export.svg";
import TableView from "../../Component/Table/Index";
import { AppConfigData, CommonService, GetPageRight } from "../../Service/CommonService";
import { checkPropertiesLimit, ExportPropertiesList, getPropertiesByPortfolioID, PropertiesPaymentInfoList } from '../../Service/PropertieService';
import { getUserPortfolioList } from '../../Service/UserSetupService';
import InPageNav from '../Common/InPageNav/Index';
import AddProperties from '../Model/AddProperties';
import { useLocation, useNavigate } from 'react-router-dom';

export const PropertiesList = () => {
    let pageRights = GetPageRight('Hotels')

    const dataSelector: any = useSelector((state: any) => state.session);
    const ClientDropdown = dataSelector.ClientListDropdown || [];
    const Token = dataSelector.access_Token;
    const [modalShowproperty, setModalShowproperty] = React.useState(false);
    let location: any = useLocation()
    const [EditPropertiesID, setEditPropertiesID] = React.useState("");
    const [EditClientID, setEditClientID] = React.useState("");
    const [EditClientNAme, setEditClientNAme] = React.useState("");
    const [PaymentInfo, setPaymentInfo] = React.useState([]);
    const [PortfolioDropdown, setPortfolioDropdown] = React.useState([])
    let [PropertiesList, setPropertiesList]: any = React.useState({ IsData: false, data: [] });
    const [SelectedData, setSelectedData]: any = React.useState({
        value: null,
        label: "Select Portfolio",
        color: "",
    })

    const clintDabelData1 = React.useMemo(
        () => [
            {
                Header: "Code",
                accessor: "code",
                className: "w-135"
            },
            {
                Header: "Client Name",
                accessor: "clientName",
                className: "w-187"
            },
            {
                Header: "Hotel Name",
                accessor: "Hotel",
                className: "w-243"
            },
            {
                Header: "Portfolios",
                accessor: "portfolio",
                className: "w-300"
            },
            {
                Header: "City",
                accessor: "city",
                className: "w-135"
            },

            {
                Header: "Contact Name",
                accessor: "contactName",
                className: "w-196"
            },
            {
                Header: "Email",
                accessor: "email",
                className: "w-300"
            },
            {
                Header: "Status",
                accessor: "status",
                className: "w-185"
            },

            {
                Header: "Action",
                accessor: "action",
                className: "actionsort stickyth"
            },
        ],
        []
    );
    const pageNav = [
        {
            name: 'Hotels List',
            link: '/properties-list',
            active: true
        }
    ]

    let clintDabelData: any = [];

    let options: any = [{
        value: null,
        label: "Select Client",
        color: ""
    }];

    ClientDropdown.map((item: any) => {
        options.push({
            value: item.clientID,
            label: item.clientName,
            color: "",
        });
    });

    let optionsPortFolio: any = [{
        value: null,
        label: "Select Portfolio",
    }];
    const navigate = useNavigate();
    const handleHotelDetailsView = (property_Data) => {


        navigate("/hotelDetails", { state: { property_id: property_Data.propertyID, client_id: property_Data.clientID } });
    };


    PortfolioDropdown.map((item: any) => {
        optionsPortFolio.push({
            value: item.PortfolioID,
            label: item.PortfolioName,
            color: "",
        });
    });

    let colorText = {
        color: "blue",
    }

    PropertiesList.data.length &&
        PropertiesList.data.map((item: any) => {
            clintDabelData.push({
                className: {
                    email: item.primaryEmailID && "link",
                    status: item.propertyStatus_Term === "Active" ? "subs-active-class-add font-0 text-center" : "subs-inactive-class-add font-0 text-center",
                    action: "stickytd"
                },
                controlRender: {
                    Hotel: (
                        <div
                            className="cursor-pointer"
                            style={{ color: "blue" }}
                            onClick={() => {
                                if (pageRights.is_view) {
                                    handleHotelDetailsView(item);
                                } else {
                                    CommonService.unauthorizedAction();
                                }
                            }}
                            role="button"
                            tabIndex={0}
                            onKeyDown={() => {
                                if (pageRights.is_view) {
                                    handleHotelDetailsView(item);
                                } else {
                                    CommonService.unauthorizedAction();
                                }
                            }}
                        >
                            {item.propertyName}
                        </div>
                    ),

                    status: item.propertyStatus_Term === "Active" ?
                        <span className="active-class-add">Active</span> :
                        <span className="inactive-class-add">Inactive</span>,
                },
                code: item.propertyCode,
                clientName: item.clientName,
                Hotel: item.propertyName,
                portfolio: item.portfolio,
                city: item.city,
                contactName: item.ownerName,
                email: item.primaryEmailID,
                status: item.propertyStatus_Term,
                action: (
                    <span className="btn-icon-grp-table justify-content-end  align-items-center">
                        <Button className="btn-icon button btn btn-primary editIcon acteditbtn" onClick={() => {
                            if (pageRights.is_update) {

                                EditProperties(item)
                            } else {

                                CommonService.unauthorizedAction()
                            }
                        }}></Button>
                    </span>
                ),


            });
        });

    let ClientLabel = localStorage.getItem('SelectedClientLabel');
    let ClientID = localStorage.getItem('SelectedClientID');


    React.useEffect(() => {

        if (location.state !== "" && location.state !== null && location.state !== undefined) {
            setSelectedData({
                value: location.state.SelectedportfolioID,
                label: location.state.SelectedportfolioName,
            })
            getPropertiesListData();
            getPortFolioDropdown();
        } else {
            getPropertiesListData();
            if (ClientLabel !== "Select Client" && ClientLabel !== null) {
                getPortFolioDropdown();
                getPaymentInfoListDropdown();
            }
        }


    }, []);

    async function EditProperties(item: any) {
        if (item) {
            if (item.propertyID && item.propertyID !== null && item.propertyID !== undefined) {
                setEditPropertiesID(item.propertyID);
                setEditClientID(item.clientID);
                setEditClientNAme(item.clientName);
            }
        }
        setModalShowproperty(true)
    }
    function showExistLimit() {

        toast.error("Contracted Properties Limit Over ", {
            theme: "colored",
        });
    }
    async function SelecteClient(event) {
        ClientID = event.value
        localStorage.setItem("SelectedClientID", event.value);
        localStorage.setItem("SelectedClientLabel", event.label);
        getPropertiesListData();


        if (event.label === "Select Client") {
            setSelectedData({
                label: "Select Portfolio",
                value: null,
            })
            setPortfolioDropdown([]);

        } else {
            getPortFolioDropdown();
            getPaymentInfoListDropdown()
        }

    }
    async function checkLimitOfProperties() {

        let input = {
            clientID: ClientID
        }
        let res: any = await checkPropertiesLimit(input, Token);

        if (res.data.success) {
            if (res.data !== undefined && res.data !== null) {
                if (res.data.data === true) {
                    setModalShowproperty(true)
                } else {
                    showExistLimit()
                }
            }
        }

    }
    async function SelectPorfolio(event: any) {
        let selectPortFolioId = event.value;
        setSelectedData({
            label: event.label,
            value: event.value,
        })
        let input = {
            portfolioID: selectPortFolioId,
            portfolioName: null,
            clientID: ClientID,
        };
        setPropertiesList({ IsData: false, data: [] });
        let resPropertiesList: any = await getPropertiesByPortfolioID(input, Token);
        if (resPropertiesList.data.success) {
            if (resPropertiesList.data !== null && resPropertiesList.data !== undefined) {
                if (resPropertiesList.data.data !== null && resPropertiesList.data.data !== undefined) {
                    if (resPropertiesList.data.data.properties !== null && resPropertiesList.data.data.properties !== undefined) {

                        setPropertiesList({ IsData: true, data: resPropertiesList.data.data.properties });
                    }
                }
            }
        }
    }

    async function getPropertiesListData() {
        let selectedClientID: any = null;
        if (localStorage.getItem('SelectedClientID') == "null") {
            selectedClientID = null;
        }
        else {

            selectedClientID = localStorage.getItem('SelectedClientID');
            if (selectedClientID === "null") {
                selectedClientID = null;
            }
        }
        let input: any = {
            portfolioID: null,
            portfolioName: null,
            clientID: selectedClientID,
        };
        if (location.state !== "" && location.state !== null && location.state !== undefined) {
            input.portfolioID = location.state.SelectedportfolioID;
        }

        setPropertiesList({ IsData: false, data: [] });
        let resPropertiesList: any = await getPropertiesByPortfolioID(input, Token);
        if (resPropertiesList.data.success) {
            if (resPropertiesList.data !== null) {
                if (resPropertiesList.data !== null && resPropertiesList.data !== undefined) {
                    let tempProperties: any = [...resPropertiesList.data.data.properties]
                    tempProperties = tempProperties.filter((itm) => itm.portfolio !== "Default Portfolio");
                    setPropertiesList({ IsData: true, data: tempProperties });
                }
            }
        }
    }

    async function getPortFolioDropdown() {
        let input = {
            clientID: localStorage.getItem('SelectedClientID') === "null" ? null : localStorage.getItem('SelectedClientID'),

        }

        let res: any = await getUserPortfolioList(input, Token);
        if (res.data.success) {
            if (res.data !== null && res.data !== undefined) {
                if (res.data.data !== null && res.data.data !== undefined) {
                    if (res.data.data.dataList1 !== null && res.data.data.dataList1 !== undefined) {
                        setPortfolioDropdown(res.data.data.dataList1);
                    }
                }
            }
        }
    }

    async function ExportPropertiesData() {
        let tempCliID: any = localStorage.getItem('SelectedClientID')
        if (tempCliID && tempCliID === "null") {
            tempCliID = null
        }
        let input = {
            portfolioID: null,
            portfolioName: null,
            clientID: tempCliID,
        };

        const res: any = await ExportPropertiesList(input, Token);
        if (res.data.success) {
            if (res.data !== null && res.data !== undefined && res.data !== "") {
                if (res.data.data !== null && res.data.data !== undefined && res.data.data !== "") {
                    if (res.data.statusCode === 200) {
                        window.open(AppConfigData.APIBasePath + res.data.data, "_blank")

                    }
                    else {
                        toast.error("Something Went Wrong", {
                            theme: "colored",
                        });
                    }
                }
            }
        }
    }
    async function getPaymentInfoListDropdown() {

        let input = {
            clientID: ClientID,
            paymentInfoID: null,
        }
        const res: any = await PropertiesPaymentInfoList(input, Token);
        if (res.data.success) {
            if (res.data !== undefined) {
                if (res.data.data !== null && res.data.data !== undefined) {
                    if (res.data.data.clientPaymentInfo !== null && res.data.data.clientPaymentInfo !== undefined) {

                        setPaymentInfo(res.data.data.clientPaymentInfo);
                    }
                }
            }
        }
    }

    return (
        <>
            <div className="propertylisting">
                <InPageNav pageNav={pageNav} />
                <div className="student-and-expert d-flex">

                    <div className="student-select-option me-3">
                        <Select
                            placeholder="Select Client"
                            className="multiselect" options={options}
                            onChange={SelecteClient}
                            defaultValue={{ label: ClientLabel || "Select Client", value: ClientID }}
                        />
                    </div>
                    <div className="student-select-option me-3">
                        <Select
                            placeholder="Select Portfolio"
                            className="multiselect"
                            options={optionsPortFolio}
                            onChange={SelectPorfolio}
                            value={SelectedData}
                        />
                    </div>
                    <div className="export-and-client d-flex mt-2">
                        <div className="export me-3">
                            <button className="modalbutn" onClick={() => {
                                if (pageRights.is_export) {
                                    ExportPropertiesData()
                                } else {
                                    CommonService.unauthorizedAction()
                                }
                            }}
                                disabled={PropertiesList.data.length ? false : true}
                            >
                                <span className="me-2">
                                    <ExportIcon />
                                </span>
                                <span>Export</span>
                            </button>
                        </div>
                        <div className="client">
                            {

                                <button
                                    className="modalbutn"
                                    onClick={checkLimitOfProperties}
                                    disabled={ClientID === "null" && ClientLabel === "Select Client" || !ClientID || !ClientLabel && true}
                                >
                                    <i className="fa-regular fa-circle-plus"></i>
                                    <span>Hotel</span>
                                </button>



                            }
                        </div>
                    </div>
                </div>
                <div className="tabelData txtmdbold">
                    <TableView columnsData={clintDabelData1} tableData={clintDabelData} isData={PropertiesList.IsData} />
                </div>
            </div>
            {

                modalShowproperty === true &&
                <AddProperties
                    getPropertiesListData={getPropertiesListData}
                    show={modalShowproperty}
                    onHide={() => {
                        setEditPropertiesID("")  // Blank The fields After close PopUp.
                        setModalShowproperty(false)

                    }}
                    EditPropertiesID={EditPropertiesID}
                    EditClientID={EditClientID}
                    EditClientNAme={EditClientNAme}
                    PaymentInfo={PaymentInfo}
                    getPaymentInfoListDropdown={getPaymentInfoListDropdown}

                />
            }
        </>
    );
}
export default PropertiesList;
