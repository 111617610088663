import React from "react";
import TableView from "../../../Component/Table/Index";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { CommonService, GetPageRight } from "../../../Service/CommonService";
import { useSelector } from "react-redux";
import AddEditCompetitorModal from "../../Model/AddEditCompetitorModal";
import { CompSetHotelGetListByProperty, CompSetHotelsDeleteByProperty } from "../../../Service/PropertieService";
import AddEditHotelDetailsCompetitorModal from "../../Model/AddEditHotelDetailsCompetitorModal";

export default function CompetitorHotelTab({ property_Data, showModel, setShowModel, property_id }) {

  let pageRights = GetPageRight('Hotels')
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  let [CompetitorList, setCompetitorList]: any = React.useState({ IsData: false, data: [] });
  const [competitorID, setCompetitorID] = React.useState("");

  const compTableColumns = React.useMemo(
    () => [
      {
        Header: "Brand Name",
        accessor: "BrandName",
      },
      {
        Header: "Hotel Name",
        accessor: "HotelName",
      },
      {
        Header: "Website URL",
        accessor: "WebsiteURL",
      },
      {
        Header: "RateShop URL",
        accessor: "RateShopURL",
      },
      {
        Header: "Action",
        accessor: "Action",
        className: "actionsort stickyth"
      },
    ],
    []
  );

  let compTableData: any = [];
  CompetitorList.data.length &&
    CompetitorList.data.map((item) => {
      compTableData.push({
        controlRender: {
          WebsiteURL: (
            <a href={item.WebsiteURL} target="_blank">
              {item.WebsiteURL}
            </a>
          ),
          RateShopURL: (
            <a href={item.RateShopURL} target="_blank">
              {item.RateShopURL}
            </a>
          ),
        },
        className: {
          Action: "stickytd"
        },
        BrandName: item.BrandName,
        HotelName: item.HotelCode + " " + item.HotelName,
        WebsiteURL: item.WebsiteURL,
        RateShopURL: item.RateShopURL,
        Action: (
          <span className="btn-icon-grp-table justify-content-end  align-items-center">
            <Button
              className="btn-icon button btn btn-primary editIcon acteditbtn"
              onClick={() => {
                if (pageRights.is_update) {
                  setCompetitorID(item.CompHotelID);
                  setShowModel(true);
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
            ></Button>
            <Button
              className="btn-icon cancelIcon"
              onClick={() => {
                if (pageRights.is_delete) {
                  DeleteHotels(item.CompHotelID);
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
            ></Button>
          </span>
        ),
      });
    });

  const fetchCompetitorListData = async () => {
    let data = {
      clientID: property_Data.client_id,
      propertyID: property_Data.property_id,
    }

    setCompetitorList({ IsData: false, data: [] });
    let res: any = await CompSetHotelGetListByProperty(data, Token);
    if (res.status === 200) {
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null) {
          if (res.data.data !== undefined && res.data.data !== null) {
            setCompetitorList({ IsData: true, data: res.data.data.dataList1 });
          } else {
            setCompetitorList({ IsData: true, data: [] });
          }
        } else {
          setCompetitorList({ IsData: true, data: [] });
        }
      } else {
        setCompetitorList({ IsData: true, data: [] });
      }
    } else {
      setCompetitorList({ IsData: true, data: [] });
    }
  };

  function DeleteHotels(CompHotelID: any) {
    Swal.fire({
      title: "Are you sure want to delete ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let input: any = {
          compHotelID: CompHotelID,
          clientID: property_Data.client_id,
        }
        let res: any = await CompSetHotelsDeleteByProperty(input, Token);
        if (res.data.success) {
          if (res.data !== undefined && res.data !== null && res.data !== "") {
            if (res.data.statusCode === 200) {
              toast.success("Competitor Hotel Successfully deleted .", {
                theme: "colored",
              });
              fetchCompetitorListData();
            }
          } else {
            toast.error(res.data.message, {
              theme: "colored",
            });
          }
        }
      }
    });
  }

  React.useEffect(() => {
    if (property_id) {
      fetchCompetitorListData();
    }
  }, []);
  return (
    <>
      <div className="opportunity-height-table">
        <TableView
          columnsData={compTableColumns}
          tableData={compTableData}
          isData={CompetitorList.IsData}
        />

        {showModel && (
          <AddEditHotelDetailsCompetitorModal
            property_Data={property_Data}
            clientID={property_Data.client_id}
            competitorID={competitorID}
            show={showModel}
            fetchCompetitorListData={fetchCompetitorListData}
            onHide={() => {
              setShowModel(false);
              setCompetitorID("");
            }}
          />
        )}
      </div>
    </>
  );
}
