const cryptoKey = "SqT_InntelligentCRM_kamlesh_393987";
const CryptoJS = require("crypto-js");
const dataValues = localStorage.getItem("accessToken") || "";
let dataStr = "";
if (dataValues !== "") {
  var bytes: any;
  bytes = CryptoJS.AES.decrypt(dataValues, cryptoKey);
  dataStr = bytes.toString(CryptoJS.enc.Utf8);
}

export const sessionInitState = {
  is_logged: localStorage.getItem("accessToken") ? true : false,
  auth_UserDetail: localStorage.getItem("authUser")
    ? JSON.parse(localStorage.getItem("authUser") || "{}")
    : null,
  access_Token: dataStr,
  sidemenu_rights : localStorage.getItem('sidemenu_rights') ? JSON.parse(localStorage.getItem('sidemenu_rights') || "{}")  : null,
  user_rights : localStorage.getItem('user_rights') ? JSON.parse(localStorage.getItem('user_rights') || "{}")  : null,
};
export const headerInitState = {
  Hotels: {
    projectTerm: [],
    propertyDetail: [],
  },
};
export const dashboardInitState = {
  Dashboard: {
    chartTable: [],
    gmailDetails: [],
    goalswithSuspect: [],
    top20Accounts: [],
  },
  SelectedPropertyID: [],
  Year: "2022",
};
