import { ENUMS } from "../Constants";

export const updateUserData = (data) => ({
  type: ENUMS.actions.UPDATE_USER,
  payload: {
    is_logged: true,
    auth_UserDetail: data.loginAdminResponse[0],
    access_Token: data.authInfoVM.accessToken,
    sidemenu_rights:  data.newRights,
    user_rights : data.roleRightInfoVM
  },
});

export const getSubscription = (data) => ({
  type: ENUMS.actions.SELECTED_SUBSCRIPTION,
  payload: data,
});
export const getClientListDropdown = (data) => ({
  type: ENUMS.actions.CLIENT_LIST,
  payload: data,
});