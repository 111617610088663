import { ENUMS, INITIALSTATES } from "../../Constants";

export default function sessionReducer(state = INITIALSTATES.sessionInitState, action) {
  switch (action.type) {
    case ENUMS.actions.UPDATE_USER:
      return action.payload;
    case ENUMS.actions.SET_IS_LOGGED:
      return {
        ...state,
        is_logged: action.payload,
      };
    case ENUMS.actions.SET_USER_DETAILS:
      return {
        ...state,
        auth_UserDetail: action.payload,
      };
    case ENUMS.actions.ACCESS_TOKEN:
      return {
        ...state,
        access_Token: action.payload,
      };
    case ENUMS.actions.SELECTED_SUBSCRIPTION:
      return { ...state, SelectedSubscription: action.payload };
    case ENUMS.actions.CLIENT_LIST:
      return { ...state, ClientListDropdown: action.payload };
    default:
      return state;
  }
}
