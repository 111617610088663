import { useState } from "react";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoginButtonIcon from "../../../src/assets/Images/Icons/LoginButtonIcon.svg";
import LoginUserIcon from "../../../src/assets/Images/Icons/LoginUserIcon.svg";
import { ReactComponent as LoginLogo } from "../../../src/assets/Images/LoginPage/loginLogo.svg";
import { updateUserData } from "../../Redux/actions";
import { Login } from "../../Service/LoginService";
import { Cookies } from "react-cookie-consent";
import { ENUMS } from "../../Constants";

function AuthLogin() {
  const [IsloginClick, setIsloginClick] = useState(false);
  const [ShowPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  let [input, setInput] = useState({
    userName: "",
    password: "",
    clientID: null,
    errors: {
      userName: "",
      password: "",
    },
  });
  const cryptoKey = "SqT_InntelligentCRM_kamlesh_393987";
  const CryptoJS: any = require("crypto-js");
  const dispatch = useDispatch();

  async function loginClick() {

    if (validate()) {
      setIsloginClick(true);
      const res: any = await Login(input);
      setIsloginClick(false);
      if (res.data.success) {
        if (res.data.statusCode === 200) {
          if (res.data.data !== null && res.data.data !== undefined) {
            if (res.data.data.loginAdminResponse !== null && res.data.data.loginAdminResponse !== undefined) {

              const data = res.data.data.loginAdminResponse;

              if (data !== null && data !== "") {
                localStorage.setItem(
                  "accessToken",
                  CryptoJS.AES.encrypt(res.data.data.authInfoVM.accessToken, cryptoKey)
                );

                localStorage.setItem("authUser", JSON.stringify(res.data.data.loginAdminResponse[0]));
                // sessionStorage.setItem("accessToken", res.data.data.authInfoVM.accessToken);
                localStorage.setItem("tokenExpire", res.data.data.authInfoVM.tokenExpiresIn);
                // Cookies.set("SystemSettingDate", res.data.data.systemSettingInfoVM.dateFormat);
                Cookies.set("SystemSettingDate", "MM/DD/YYYY");


                let initialRights = ENUMS.side_menu
                let newRights = {}
                Object.keys(initialRights).forEach((key_id) => {
                  if (initialRights[key_id].hasChild) {
                    let newChild: any = []
                    initialRights[key_id].child.forEach((ch_itm) => {
                      let resData = res.data.data.roleRightInfoVM.filter((itm) => itm.moduleName == ch_itm.moduleName)
                      newChild = [...newChild, {
                        ...ch_itm,
                        is_view: resData[0].isView,
                        is_create: resData[0].isCreate,
                        is_update: resData[0].isUpdate,
                        is_delete: resData[0].isDelete,
                        is_export: resData[0].isExport
                      }]
                      newRights = {
                        ...newRights,
                        [key_id]: {
                          ...ENUMS.side_menu[key_id],
                          child: newChild
                        }
                      }
                    })
                  } else {
                    let resData = res.data.data.roleRightInfoVM.filter((itm) => itm.moduleName == initialRights[key_id].moduleName)
                    newRights = {
                      ...newRights,
                      [key_id]: {
                        ...ENUMS.side_menu[key_id],
                        is_view: resData[0].isView,
                        is_create: resData[0].isCreate,
                        is_update: resData[0].isUpdate,
                        is_delete: resData[0].isDelete,
                        is_export: resData[0].isExport
                      }
                    }
                  }
                })
                localStorage.setItem("sidemenu_rights", JSON.stringify(newRights));
                localStorage.setItem("user_rights", JSON.stringify(res.data.data.roleRightInfoVM));
                // res.data.data.newRights = newRights
                let newJson = {
                  ...res.data.data,
                  newRights: newRights
                }
                dispatch(updateUserData(newJson));
                // dispatch(setSideMenuRights(newRights));
                // dispatch(setUserRights(res.data.data.roleRightInfoVM));
                toast.success("User SuccessFully Logged In", {
                  // icon: "🚀",
                  theme: "colored",
                });
                navigate("/");
              }
            }
          }
        }
        else {
          toast.error(res.data.message, {
            // icon: "🚀",
            theme: "colored",
          });
          // let Message = res.data.message;
          // ToasterError(Message);
        }
      }
      else {
        toast.error("Something Went Wrong!", {
          // icon: "🚀",
          theme: "colored",
        });
      }
    }
  }


  function validate() {

    let isValid = true;
    const errors: any = {};

    if (!input.password || !input.userName) {

      // errors.password = "Password Or UserName are required";
      isValid = false;
      toast.error("UserName & Password are required", {
        theme: "colored",
      });
    } else {
      errors.password = "";
    }

    if (!input.userName) {
      errors.userName = "Email";
      isValid = false;
    } else {
      errors.userName = "";
    }

    if (!input.password) {
      errors.password = "Password is required";
      isValid = false;
    } else {
      errors.password = "";
    }

    return isValid;
  }

  function showPassword() {
    setShowPassword(!ShowPassword);
  }
  return (
    <>
      <div className="d-flex">
        <div className="innerSecLogin">
          <div className="loginLogo d-flex justify-content-center">
            <span>
              <LoginLogo />
            </span>
          </div>
          <div className="loginHead">
            <h1>Login</h1>
          </div>
          <Form>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <span>
                  <img src={LoginUserIcon} alt="" />
                </span>
              </InputGroup.Text>
              <FormControl
                onChange={(e) =>
                  setInput({
                    ...input,
                    [e.target.name]: e.target.value,
                  })
                }
                // className={`mt-4 title-textbox ${input.errors.userName && "invalid"
                //   }`}
                type="email"
                name="userName"
                placeholder="Username"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
            {input.errors.userName && (
              <span className="error-message">
                {input.errors.userName}
              </span>
            )}
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                {ShowPassword ? (
                  <span
                    role="button"
                    tabIndex={-1}
                    onClick={() => showPassword()}
                    onKeyDown={() => showPassword()}
                  >
                    <i className="fa-regular fa-eye-slash"></i>
                  </span>
                ) : (
                  <span
                    role="button"
                    tabIndex={-1}
                    onClick={() => showPassword()}
                    onKeyDown={() => showPassword()}
                  >
                    <i className="fa-regular fa-eye"></i>
                  </span>
                )}
              </InputGroup.Text>
              <FormControl
                onChange={(e) =>
                  setInput({
                    ...input,
                    [e.target.name]: e.target.value,
                  })
                }
                type={ShowPassword ? "text" : "password"}
                placeholder="Password"
                aria-label="Password"
                aria-describedby="basic-addon1"
                name="password"
                autoComplete="current-password"
                id="password"
              // className={`mt-4 title-textbox ${input.errors.password && "invalid"
              //   }`}
              />
              {input.errors.password && (
                <span className="error-message">
                  {input.errors.password}
                </span>
              )}
              {/* <div className="eye-visible">
                {ShowPassword ? (
                  <span
                    role="button"
                    tabIndex={-1}
                    onClick={() => showPassword()}
                    onKeyDown={() => showPassword()}
                  >
                    <i className="fa-solid fa-eye-slash"></i>
                  </span>
                ) : (
                  <span
                    role="button"
                    tabIndex={-1}
                    onClick={() => showPassword()}
                    onKeyDown={() => showPassword()}
                  >
                    <i className="fa-solid fa-eye"></i>
                  </span>
                )}
              </div> */}
            </InputGroup>
            <Form.Group className="form-button d-flex justify-content-between flex-column">
              {IsloginClick === false ? (
                <button onClick={loginClick} className="btn btn-primary mylgnbtn" type="button">
                  <span className="me-3">
                    <img src={LoginButtonIcon} alt="" />
                  </span>
                  Login
                </button>
              ) : (
                <button className="btn btn-primary mylgnbtn" type="button">
                  <span className="me-3">
                    <i className="fa-duotone fa-spinner fa-spin"></i>
                  </span>
                  Login...
                </button>
              )}
              {/* <Link className="forgot-link" to="/forgotPassword">
                Forgot Password ?
              </Link> */}
            </Form.Group>
          </Form>
        </div>
      </div>
    </>
  );
}

export default AuthLogin;
