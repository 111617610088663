import React from 'react'
import { Modal } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { getAllMails, getMailDetails } from "../../Service/MailInboxService";
import { AppConfigData, getDateInFormat, SourceAPIList, ToasterError } from "../../Service/CommonService";
import Skeleton from "react-loading-skeleton";

export default function MailInbox({ modalShow, setModalShow }) {
    const dataSelector: any = useSelector((state: any) => state.session);
    const Token = dataSelector.access_Token;
    const handleClosePopup = () => {
        setModalShow(false)
    }
    const [MailList, setMailList]: any = React.useState({ IsData: false, data: [] });
    const [MailDetails, setMailDetails]: any = React.useState({ IsData: true, data: "" });
    const ForLoader = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    const [_PageNo, set_PageNo]: any = React.useState(1);

    const [IsLastPage, setIsLastPage]: any = React.useState(false);

    async function getAllMailsData(PageNo, NoofData) {
        setMailList({ IsData: false, data: [] });
        let res: any = await getAllMails(PageNo, NoofData, Token);
        if (res.data.success) {
            if (res !== undefined) {
                if (res.data !== undefined) {
                    if (res.data.data !== undefined && res.data.data !== null) {
                        set_PageNo(PageNo);
                        setMailList({ IsData: true, data: res.data.data.reverse() });
                    }
                    else {
                        setMailList({ IsData: true, data: [] });
                        setIsLastPage(true);
                    }
                }
                else {
                    setMailList({ IsData: true, data: [] });
                    setIsLastPage(true);
                }
            }
            else {
                setMailList({ IsData: true, data: [] });
                setIsLastPage(true);
            }
        }
        else {
            setMailList({ IsData: true, data: [] });
            setIsLastPage(true);
        }
    }

    async function getMailDetailsData(Index, MessageId) {

        setMailDetails({ IsData: false, data: [] });
        let res: any = await getMailDetails(Index, MessageId, Token);
        if (res.data.success) {
            if (res !== undefined) {
                if (res.data !== undefined) {
                    if (res.data.data !== undefined && res.data.data !== null) {
                        setMailDetails({ IsData: true, data: res.data.data });
                    }
                    else {
                        setMailDetails({ IsData: true, data: "" });
                    }
                }
                else {
                    setMailDetails({ IsData: true, data: "" });
                }
            }
            else {
                setMailDetails({ IsData: true, data: "" });
            }
        }
        else {
            setMailDetails({ IsData: true, data: "" });
        }
    }

    function PageChange(No) {
        let PageNo = _PageNo;
        PageNo = PageNo + No;
        getAllMailsData(PageNo, 10)
    }

    React.useEffect(() => {
        getAllMailsData(1, 10);
    }, []);

    return (<Modal
        show={modalShow}
        className="clientpoupadd "
        size={"fullscreen"}
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => handleClosePopup()}
        centered
    >

        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Gmail Inbox</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
                <div className="col-4">
                    {
                        MailList.IsData ?
                            <>{
                                MailList.data.map((item, index) => (
                                    <div className="card" key={index} onClick={e => getMailDetailsData(item.index, encodeURIComponent(item.messageId))}>
                                        <div className="card-body" >
                                            <div className="card-text">From : {item.sender} <small className="text-muted float-end">{getDateInFormat(item.date)}</small></div>
                                            <div className="card-text">Subject : {item.subject}</div>
                                        </div>
                                    </div>
                                ))
                            }
                                <div className="mt-2">

                                    {
                                        IsLastPage !== true &&
                                        <button type="button" className="savebtnclient btn btn-primary float-end" onClick={e => PageChange(1)} ><i className="fa-regular fa-arrow-right me-2"></i>Next</button>
                                    }
                                    {
                                        _PageNo > 1 &&
                                        <button type="button" className="savebtnclient btn btn-primary float-end me-2" onClick={e => PageChange(-1)} ><i className="fa-regular fa-arrow-left me-2"></i>Previous</button>
                                    }
                                </div>
                            </>
                            :
                            ForLoader.map((item, index) => (
                                <div className="card" key={index}>
                                    <div className="card-body" >
                                        <div className="card-text"><Skeleton count={1} height={20} /></div>
                                        <div className="card-text"><Skeleton count={1} height={20} /></div>
                                    </div>
                                </div>
                            ))

                    }
                </div>
                <div className="col-8">
                    <div className="row">
                        <div className="col-12">
                            <button type="button" className="savebtnclient btn btn-primary float-end me-2">Link With Lead &nbsp;<i className="fa-regular fa-arrow-right"></i></button>
                            <button type="button" className="savebtnclient btn btn-primary float-end me-2">Link With Account &nbsp; <i className="fa-regular fa-arrow-right"></i></button>
                            <button type="button" className="savebtnclient btn btn-primary float-end me-2">Link With RFP &nbsp;<i className="fa-regular fa-arrow-right"></i></button>
                            <button type="button" className="savebtnclient btn btn-primary float-end me-2">Link With Group &nbsp; <i className="fa-regular fa-arrow-right"></i></button>

                        </div>
                    </div>
                    {
                        MailDetails.IsData ?
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: MailDetails.data,
                                }}
                            ></p>
                            :
                            <Skeleton count={20} height={20} />
                    }
                </div>
            </div>
        </Modal.Body>
    </Modal>
    )
}
