
import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { DisplayText, fnCheckValidationOfObject, SourceAPIList } from '../../Service/CommonService';
// import { toast } from 'react-toastify';
import { ToasterError } from '../../Service/CommonService';
import { SavePaymentInfoList } from '../../Service/PropertieService';
import InputMasking from '../Properties/MaskingComponent'
// import { SaveORUpdatePortfolio } from '../../Service/PortFolioService';
import PatternFormat from "react-number-format";

const AddPaymentInfoModal = (props: any) => {

  const [PaymentTypeList, setPaymentTypeList] = useState([]);
  const ClientID: any = localStorage.getItem("SelectedClientID");
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;

  const [SavePaymentInfo, setSavePaymentInfo] = useState<any>(
    {
      clientID: ClientID,
      paymentOption: "",
      invoiceContactName: "",
      invoiceContactEmail: "",
      invoiceContactPhone: "",
      updateBy: "",
      customPayment: "",
      errors: {
        paymentOption: "",
        invoiceContactName: "",
        invoiceContactEmail: "",
        // invoiceContactPhone: "",
        ValidationRules: [
          {
            FieldName: "paymentOption",
            ValidationType: "required",
            ValidationMessage: "This field is required.",
          },
          {
            FieldName: "invoiceContactName",
            ValidationType: "required",
            ValidationMessage: "This field is required.",
          },
          {
            FieldName: "invoiceContactEmail",
            ValidationType: "required",
            ValidationMessage: "This field is required.",
          },
          {
            FieldName: "invoiceContactEmail",
            ValidationType: "email",
            ValidationMessage: "Enter Valid Email Value",
          },
        ],
      },
    })

  // let [savePortfolio, setsavePortfolio] = useState({
  //   portfolioID: null,
  //   portfolioName: "",
  //   isAllPortfolio: false,
  //   portfolioCode: "",
  //   clientID: props.ClientID && props.ClientID,
  //   isActive: true,
  //   updateBy: props.userID,
  //   errors: {
  //     portfolioName: "",
  //     ValidationRules: [
  //       {
  //         FieldName: "portfolioName",
  //         ValidationType: "required",
  //         ValidationMessage: "This field is required.",
  //       },
  //     ],
  //   },
  // })
  React.useEffect(() => {

    getPaymentOptionList();
  }, [])

  async function getPaymentOptionList() {
    // let input = {
    //   category: "ClientPayment",
    //   clientID: null,
    //   form: null,
    // };
    let input = {
      clientID: null,
      category: "ClientPayment"
    }
    const res: any = await SourceAPIList(input, Token);
    if (res.data.data !== null && res.data.data !== undefined) {
      // setPaymentTypeList(res.data.data.sourceProjectTermResponse);
      setPaymentTypeList(res.data.data.projectTermL);
    }
  }

  async function SavePaymentInfoData() {
    // event.preventDefault();
    let obj = fnCheckValidationOfObject(SavePaymentInfo);
    setSavePaymentInfo({
      ...obj.obj,
    });
    let isValid = obj.isValid
    if (obj.obj.paymentOption === 'Custom') {
      if (SavePaymentInfo.customPayment === "" || !SavePaymentInfo.customPayment) {
        obj.obj.errors['customPayment'] = "This field is required."
        isValid = false
      } else {
        obj.obj.errors['customPayment'] = ""
      }

    }
    obj.isValid = isValid
    if (obj.isValid) {
      let res: any = []
      let input = {
        clientID: ClientID,
        paymentOption: SavePaymentInfo.paymentOption,
        invoiceContactName: SavePaymentInfo.invoiceContactName,
        invoiceContactEmail: SavePaymentInfo.invoiceContactEmail,
        invoiceContactPhone: SavePaymentInfo.invoiceContactPhone,
        customPayment: SavePaymentInfo.customPayment,
        updateBy: "95f6fca5-ee0b-4324-8cd6-609a8fa9f1e1"
      }
      res = await SavePaymentInfoList(input, Token);
      if (res.data !== undefined) {
        if (res.data.statusCode === 200) {
          toast.success(res.data.message, {
            theme: "colored",
          });
          handleClose();
          // props.GetPortFolioList(props.ClientID);
        }
      }
      else {
        ToasterError(res.message);
      }
    }
    // else {
    //   props.onHide(true);
    // }
  }

  const handleClose = () => {
    setSavePaymentInfo([])

    props.onHide(false);
    props.getPaymentInfoListDropdown && props.getPaymentInfoListDropdown();
  }


  return (

    <Modal
      {...props}
      className="clientpoupadd"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Payment Info </Modal.Title> {/* {
          savePortfolio.portfolioID !== null && savePortfolio.portfolioID !== undefined && savePortfolio.portfolioID !== "" ?
            <Modal.Title id="contained-modal-title-vcenter">Edit Portfolio</Modal.Title>
            :
            
        } */}
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <div className="col-md-6">
              <Form.Group>
                <Form.Label>Payment Option</Form.Label>
                <Form.Select aria-label="Default select example"
                  value={SavePaymentInfo && SavePaymentInfo.paymentOption}
                  onChange={(e) =>
                    setSavePaymentInfo({
                      ...SavePaymentInfo,
                      ["paymentOption"]: (e.target.value),
                    })
                  }
                  className={`form-control ${SavePaymentInfo.errors && SavePaymentInfo.errors.paymentOption && "is-invalid"}`}
                >
                  <option value="">Select Payment Option</option>
                  {PaymentTypeList !== null && PaymentTypeList !== undefined &&
                    PaymentTypeList.map((item: any, index) => (
                      <option key={index} value={item.term}>
                        {item.term}
                      </option>
                    ))}
                </Form.Select>
                {SavePaymentInfo.errors && SavePaymentInfo.errors.paymentOption && (
                  <Form.Control.Feedback type="invalid">
                    {SavePaymentInfo.errors.paymentOption}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group>
                <Form.Label>Invoice Contact Name</Form.Label>
                <Form.Control
                  type="text" maxLength={160}
                  value={SavePaymentInfo && SavePaymentInfo.invoiceContactName}
                  placeholder={DisplayText.InvoiceContactName}
                  // value={SavePaymentInfo.invoiceContactName}
                  onChange={(e) =>
                    setSavePaymentInfo({
                      ...SavePaymentInfo,
                      ["invoiceContactName"]: e.target.value,
                    })
                  }
                  isInvalid={SavePaymentInfo.errors && SavePaymentInfo.errors.invoiceContactName} />
                {SavePaymentInfo.errors && SavePaymentInfo.errors.invoiceContactName && (
                  <Form.Control.Feedback type="invalid">
                    {SavePaymentInfo.errors.invoiceContactName}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  maxLength={160}
                  value={SavePaymentInfo && SavePaymentInfo.invoiceContactEmail}

                  placeholder={DisplayText.Email}

                  onChange={(e) =>
                    setSavePaymentInfo({
                      ...SavePaymentInfo,
                      ["invoiceContactEmail"]: e.target.value,
                    })
                  }
                  isInvalid={SavePaymentInfo.errors && SavePaymentInfo.errors.invoiceContactEmail} />
                {SavePaymentInfo.errors && SavePaymentInfo.errors.invoiceContactEmail && (
                  <Form.Control.Feedback type="invalid">
                    {SavePaymentInfo.errors.invoiceContactEmail}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group>
                <Form.Label>Phone no.</Form.Label>
                {/* <InputMasking
                  onChangeInput={(value) =>
                    setSavePaymentInfo({
                      ...SavePaymentInfo,
                      ["invoiceContactPhone"]: value,
                    })
                  }
                  currentValue={SavePaymentInfo && SavePaymentInfo.invoiceContactPhone}
                /> */}


                {/* <InputMasking
                  onChangeInput={(value) =>
                    setSavePaymentInfo({
                      ...SavePaymentInfo,
                      ["invoiceContactPhone"]: value,
                    })
                  }
                  currentValue={SavePaymentInfo.invoiceContactPhone || ""}
                /> */}
                <PatternFormat
                  className="form-control"
                  placeholder="Enter Phone no."
                  displayType="input"
                  value={SavePaymentInfo.phone}
                  format="###-###-####"
                  onChange={(e) =>
                    setSavePaymentInfo({
                      ...SavePaymentInfo,
                      ["invoiceContactPhone"]: e.target.value,
                    })
                  }
                />
                {/* <Form.Control
                  type="text" maxLength={256}
                  // placeholder={DisplayText.}
                  value={SavePaymentInfo && SavePaymentInfo.invoiceContactPhone}
                  onChange={(e) =>
                    setSavePaymentInfo({
                      ...SavePaymentInfo,
                      ["invoiceContactPhone"]: e.target.value,
                    })
                  }
                  isInvalid={SavePaymentInfo.errors && SavePaymentInfo.errors.invoiceContactPhone} />
                {SavePaymentInfo.errors.invoiceContactPhone && (
                  <Form.Control.Feedback type="invalid">
                    {SavePaymentInfo.errors.invoiceContactPhone}
                  </Form.Control.Feedback>
                )} */}

              </Form.Group>
            </div>

            <div className="row">
              {SavePaymentInfo.paymentOption === "Custom" ?
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label>Custom Payment Option</Form.Label>
                    <Form.Control
                      type="text"
                      minLength={0}
                      placeholder="Custom Payment Option"
                      value={SavePaymentInfo.customPayment}
                      isInvalid={SavePaymentInfo.errors.customPayment}
                      onChange={(e) =>
                        setSavePaymentInfo({
                          ...SavePaymentInfo,
                          ["customPayment"]: (e.target.value),
                        })
                      }
                    />
                    {SavePaymentInfo.errors.customPayment && (
                      <Form.Control.Feedback type="invalid">
                        {SavePaymentInfo.errors.customPayment}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                </div> : ""
              }
            </div>

          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="savebtnclient" type="button"
          onClick={SavePaymentInfoData}>

          <i className="fa-solid fa-floppy-disk me-2"></i>Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddPaymentInfoModal