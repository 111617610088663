import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, FloatingLabel, Table, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import PageLoader from "../../../Component/Loader/PageLoader";

import { ReactSearchAutocomplete } from "react-search-autocomplete";
import CreatableSelect from "react-select/creatable";
import SquarePlus from "../../../assets/Images/Icons/square-plus.svg";
import { CommonService, GetPageRight } from "../../../Service/CommonService";
import {
  GetFoodAndBeveragesList,
  FoodAndBeveragesSave,
  AvEquipmentsAndMISCSave,
  GetAvEquipmentsAndMISCList,
} from "../../../Service/PropertieService";
import { getProjectTermByCategory } from "../../../Service/UserSetupService";

export default function FoodAndAv({ property_Data }) {
  let page_rights_Hotel = GetPageRight('Hotels')

  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;

  const [fabTypesList, setFabTypesList]: any = useState([]);
  const [aveTypesList, setAveTypesList]: any = useState([]);

  const [loading, setLoading] = useState(true);
  const [avLoading, setAvLoading] = useState(true);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [dataRes, setDataRes]: any = useState({
    1: {
      itemName: "",
      itemType_Term: "Food",
      itemCostPerUnit: 0,
      taxRatePerc: 0,
      orderNo: 0,
      description: "",
    },
  }); // Food State
  const [dataResEr, setDataResEr]: any = useState({
    1: {
      itemName: "",
    },
  }); // Food Error

  const [submitLoadingBeverage, setSubmitLoadingBeverage] = useState(false);
  const [countBeverage, setCountBeverage] = useState(1);

  const [dataResBeverage, setDataResBeverage]: any = useState({
    1: {
      itemName: "",
      itemType_Term: "Beverage",
      itemCostPerUnit: 0,
      taxRatePerc: 0,
      orderNo: 0,
      description: "",
    },
  }); // Beverage State
  const [dataResBeverageEr, setDataResBeverageEr]: any = useState({
    1: {
      itemName: "",
    },
  }); // Food Error

  const [avSubmitLoading, setAvSubmitLoading] = useState(false);
  const [avCount, setAvCount] = useState(1);

  const [avDataRes, setAvDataRes] = useState({
    1: {
      avEquipName: "",
      avEquipType_Term: "",
      avEquipCost: 0,
      taxRatePerc: 0,
      orderNO: 0,
    },
  }); // Av state
  const [avDataEr, setAvDataEr]: any = useState({
    1: {
      avEquipName: "",
      avEquipType_Term: "",
    },
  }); // Av Error

  //#region Validation Functions : Food, Av, Beverage ;

  const validateFoodData = () => {
    let isValidate = true;
    let errosJson = {};
    Object.keys(dataResEr).forEach((er_key) => {
      errosJson = {
        ...errosJson,
        [er_key]: {
          itemName: "",
        },
      };
    });

    Object.keys(dataRes).forEach((key_gr) => {
      if (dataRes[key_gr].itemName === "") {
        errosJson = {
          ...errosJson,
          [key_gr]: {
            ...errosJson[key_gr],
            itemName: "This field is required.",
          },
        };
        isValidate = false;
      }
    });
    setDataResEr(errosJson);

    return isValidate;
  };

  const validateBeverageData = () => {
    let isValidate = true;
    let errosJson = {};
    Object.keys(dataResBeverageEr).forEach((er_key) => {
      errosJson = {
        ...errosJson,
        [er_key]: {
          itemName: "",
        },
      };
    });

    Object.keys(dataResBeverage).forEach((key_gr) => {
      if (dataResBeverage[key_gr].itemName === "") {
        errosJson = {
          ...errosJson,
          [key_gr]: {
            ...errosJson[key_gr],
            itemName: "This field is required.",
          },
        };
        isValidate = false;
      }
    });
    setDataResBeverageEr(errosJson);

    return isValidate;
  };

  const validAvEquipmentData = () => {
    let isValidate = true;
    let errosJson = {};
    Object.keys(avDataEr).forEach((er_key) => {
      errosJson = {
        ...errosJson,
        [er_key]: {
          avEquipName: "",
          avEquipType_Term: "",
        },
      };
    });

    Object.keys(avDataRes).forEach((key_gr) => {
      if (avDataRes[key_gr].avEquipName === "") {
        errosJson = {
          ...errosJson,
          [key_gr]: {
            ...errosJson[key_gr],
            avEquipName: "This field is required.",
          },
        };
        isValidate = false;
      }
      if (avDataRes[key_gr].avEquipType_Term === "") {
        errosJson = {
          ...errosJson,
          [key_gr]: {
            ...errosJson[key_gr],
            avEquipType_Term: "This field is required.",
          },
        };
        isValidate = false;
      }
    });
    setAvDataEr(errosJson);

    return isValidate;
  };

  //#endregion End Validation Function Scope ;

  const handleSaveData = async () => {

    let dataToSend: any = [];

    Object.keys(dataRes).map((itm: any, idx) => {
      dataToSend.push({
        itemName: dataRes[itm].itemName,
        itemType_Term: dataRes[itm].itemType_Term,
        itemCostPerUnit: dataRes[itm].itemCostPerUnit,
        taxRatePerc: dataRes[itm].taxRatePerc,
        orderNo: idx,
        description: dataRes[itm].description,
      });
    });

    if (validateFoodData()) {
      let data = {
        listFoodAndBeverages: dataToSend,
        hotelID: property_Data.property_id,
        clientID: property_Data.client_id,
        itemType_Term: "Food",
      };
      setSubmitLoading(true);
      let res: any = await FoodAndBeveragesSave(data, Token);
      if (res.status === 200) {
        if (res.data.success) {
          setSubmitLoading(false);
          toast.success(res.data.message);
        } else {
          setSubmitLoading(false);
        }
      } else {
        setSubmitLoading(false);
      }
    }
  };

  const handleSaveBeverageData = async () => {
    let dataToSend: any = [];
    Object.keys(dataResBeverage).map((itm: any, idx) => {
      dataToSend.push({
        itemName: dataResBeverage[itm].itemName,
        itemType_Term: dataResBeverage[itm].itemType_Term,
        itemCostPerUnit: dataResBeverage[itm].itemCostPerUnit,
        taxRatePerc: dataResBeverage[itm].taxRatePerc,
        orderNo: idx,
        description: dataResBeverage[itm].description,
      });
    });

    if (validateBeverageData()) {
      let data = {
        listFoodAndBeverages: dataToSend,
        hotelID: property_Data.property_id,
        clientID: property_Data.client_id,
        itemType_Term: "Beverage",
      };
      setSubmitLoadingBeverage(true);
      let res: any = await FoodAndBeveragesSave(data, Token);
      if (res.status === 200) {
        if (res.data.success) {
          setSubmitLoadingBeverage(false);
          toast.success(res.data.message);
        } else {
          setSubmitLoadingBeverage(false);
        }
      } else {
        setSubmitLoading(false);
      }
    }
  };

  const handleAvSaveData = async () => {

    let dataToSend: any = [];
    Object.keys(avDataRes).map((itm: any, idx) => {
      dataToSend.push({
        avEquipName: avDataRes[itm].avEquipName,
        avEquipType_Term: avDataRes[itm].avEquipType_Term,
        avEquipCost: avDataRes[itm].avEquipCost,
        taxRatePerc: avDataRes[itm].taxRatePerc,
        orderNO: idx,
      });
    });

    if (validAvEquipmentData()) {
      let data = {
        listAvEquipmentANDMISC: dataToSend,
        hotelID: property_Data.property_id,
        clientID: property_Data.client_id,
      };
      setAvSubmitLoading(true);
      let res: any = await AvEquipmentsAndMISCSave(data, Token);
      if (res.status === 200) {
        if (res.data.success) {
          setAvSubmitLoading(false);
          toast.success(res.data.message);
        } else {
          setAvSubmitLoading(false);
        }
      } else {
        setAvSubmitLoading(false);
      }
    }
  };

  const fetchAvData = async () => {
    let data = {
      hotelID: property_Data.property_id,
      clientID: property_Data.client_id,
      isNew: true,
    };

    let res: any = await GetAvEquipmentsAndMISCList(data, Token);
    if (res.status === 200) {
      if (res.data.success) {
        if (res.data.data) {
          let jsonToSet: any = {};
          res.data.data.dataList1.forEach((res_data_lst, i) => {
            jsonToSet = {
              ...jsonToSet,
              [i]: {
                avEquipName: res_data_lst.AVEquipName ? res_data_lst.AVEquipName : "",
                avEquipType_Term: res_data_lst.AVEquipType_Term
                  ? res_data_lst.AVEquipType_Term
                  : "",
                avEquipCost: res_data_lst.AVEquipCost ? res_data_lst.AVEquipCost : 0,
                taxRatePerc: res_data_lst.TaxRatePerc ? res_data_lst.TaxRatePerc : 0,
                orderNO: res_data_lst.OrderNO ? res_data_lst.OrderNO : 0,
              },
            };
          });
          setAvCount(res.data.data.dataList1.length - 1);
          setAvDataRes(jsonToSet);
          setAvLoading(false);
        } else {
          setAvLoading(false);
        }
      } else {
        setAvLoading(false);
      }
    } else {
      setAvLoading(false);
    }
  };

  const fetchFoodData = async () => {

    let data = {
      hotelID: property_Data.property_id,
      clientID: property_Data.client_id,
      isNew: true,
      itemType_Term: "Food",
    };
    let res: any = await GetFoodAndBeveragesList(data, Token);
    if (res.status === 200) {
      if (res.data.success) {
        if (res.data.data) {
          let jsonToSet: any = {};

          res.data.data.dataList1.forEach((res_data_lst, i) => {
            if (res_data_lst.ItemType_Term === "Food") {
              jsonToSet = {
                ...jsonToSet,
                [i]: {
                  itemName: res_data_lst.ItemName ? res_data_lst.ItemName : "",
                  itemType_Term: res_data_lst.ItemType_Term ? res_data_lst.ItemType_Term : "",
                  itemCostPerUnit: res_data_lst.ItemCostPerUnit ? res_data_lst.ItemCostPerUnit : 0,
                  taxRatePerc: res_data_lst.TaxRatePerc ? res_data_lst.TaxRatePerc : 0,
                  orderNo: res_data_lst.OrderNo ? res_data_lst.OrderNo : 0,
                  description: res_data_lst.Description ? res_data_lst.Description : "",
                },
              };
            }
          });
          setDataRes(jsonToSet);
          setCount(Object.keys(jsonToSet).length - 1);

          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const fetchBeverageData = async () => {
    let data = {
      hotelID: property_Data.property_id,
      clientID: property_Data.client_id,
      isNew: true,
      itemType_Term: "Beverage",
    };

    let res: any = await GetFoodAndBeveragesList(data, Token);
    if (res.status === 200) {
      if (res.data.success) {
        if (res.data.data) {
          let jsonToSetBeverage: any = {};
          res.data.data.dataList1.forEach((res_data_lst, i) => {
            if (res_data_lst.ItemType_Term === "Beverage") {
              jsonToSetBeverage = {
                ...jsonToSetBeverage,
                [i]: {
                  itemName: res_data_lst.ItemName ? res_data_lst.ItemName : "",
                  itemType_Term: res_data_lst.ItemType_Term ? res_data_lst.ItemType_Term : "",
                  itemCostPerUnit: res_data_lst.ItemCostPerUnit ? res_data_lst.ItemCostPerUnit : 0,
                  taxRatePerc: res_data_lst.TaxRatePerc ? res_data_lst.TaxRatePerc : 0,
                  orderNo: res_data_lst.OrderNo ? res_data_lst.OrderNo : 0,
                  description: res_data_lst.Description ? res_data_lst.Description : "",
                },
              };
            }
          });

          setDataResBeverage(jsonToSetBeverage);
          setCountBeverage(Object.keys(jsonToSetBeverage).length - 1);

          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const fetchPriorData = async () => {
    let input_data = {
      clientID: property_Data.client_id,
      category: "FABType",
    };
    let input_ave = {
      clientID: property_Data.client_id,
      category: "AVEType",
    };

    let res = await getProjectTermByCategory(input_data, Token);
    let res_ave = await getProjectTermByCategory(input_ave, Token);
    if (res.status === 200) {
      if (res.data.success) {
        if (res.data.data !== undefined && res.data.data) {
          if (
            res.data.data.dataList1 !== undefined &&
            res.data.data.dataList1 &&
            res.data.data.dataList1.length
          ) {
            let fdTypeToAppend: any = [];
            res.data.data.dataList1.forEach((itm: any) => {
              fdTypeToAppend = [
                ...fdTypeToAppend,
                {
                  label: itm.TermCode,
                  value: itm.TermID,
                },
              ];
            });

            setFabTypesList(fdTypeToAppend);
          }
        }
      }
    }
    if (res_ave.status === 200) {
      if (res_ave.data.success) {
        if (res_ave.data.data !== undefined && res_ave.data.data) {
          if (
            res_ave.data.data.projectTermL !== undefined &&
            res_ave.data.data.projectTermL &&
            res_ave.data.data.projectTermL.length
          ) {
            let aveTypeToAppend: any = [];
            res_ave.data.data.projectTermL.forEach((itm: any) => {
              aveTypeToAppend = [
                ...aveTypeToAppend,
                {
                  label: itm.termCode,
                  value: itm.termID,
                },
              ];
            });

            setAveTypesList(aveTypeToAppend);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (property_Data.property_id) {
      fetchPriorData();
      fetchFoodData();
      fetchBeverageData();
      fetchAvData();
    }
  }, []);

  return (
    <>
      <div className="information-division pb-2">
        <h3 className="inner-header m-0">Food</h3>
        {!loading ? (
          <div className="tab-inner-box position-relative">
            <Row>
              <Col>
                <Form>
                  <div className="customtablemetting">
                    <Table responsive bordered className="table-without-space">
                      <thead>
                        <tr className="h-40">
                          <th>Item Name</th>
                          <th>Type</th>
                          <th>Cost Per Unit($)</th>
                          <th>Description</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(dataRes).map((data_key) => {
                          return (
                            <>
                              <tr>
                                <td className="bordernone">
                                  <Form.Group>
                                    <Form.Control
                                      className={`input-tabal ps-0 pe-0 creatableSpace ${dataResEr[data_key] && dataResEr[data_key].itemName !== ""
                                        ? "red-border-masking ClassSelectCreatable"
                                        : ""
                                        }`}
                                      type="text"
                                      onChange={(e) => {
                                        setDataRes({
                                          ...dataRes,
                                          [data_key]: {
                                            ...dataRes[data_key],
                                            itemName: e.target.value ? e.target.value : "",
                                          },
                                        });
                                      }}
                                      value={dataRes[data_key].itemName}
                                    />
                                  </Form.Group>
                                </td>
                                <td
                                  style={{ padding: 0, width: "16.6%" }}

                                  className="bordernone"
                                >

                                  <Form.Group>
                                    <Form.Control
                                      className="input-tabal ps-0 pe-0"
                                      type="text"

                                      value={dataRes[data_key].itemType_Term}
                                    />
                                  </Form.Group>
                                </td>
                                <td

                                  className="bordernone"
                                >
                                  <Form.Group>
                                    <Form.Control
                                      className="input-tabal ps-0 pe-0"
                                      type="number"
                                      onWheel={(event: any) => {
                                        event.target.blur();
                                      }}
                                      onKeyDown={(e: any) => {
                                        if (e.keyCode == "38" || e.keyCode == "40") {
                                          e.stopPropagation();
                                          e.preventDefault();
                                        }
                                      }}
                                      onChange={(e) => {
                                        setDataRes({
                                          ...dataRes,
                                          [data_key]: {
                                            ...dataRes[data_key],
                                            itemCostPerUnit: e.target.value ? e.target.value : 0,
                                          },
                                        });
                                      }}
                                      value={
                                        dataRes[data_key].itemCostPerUnit
                                          ? dataRes[data_key].itemCostPerUnit
                                          : ""
                                      }
                                    />
                                  </Form.Group>
                                </td>

                                <td
                                  className="bordernone">
                                  <Form.Group>
                                    <Form.Control
                                      className="input-tabal ps-0 pe-0"
                                      type="text"
                                      onChange={(e) => {
                                        setDataRes({
                                          ...dataRes,
                                          [data_key]: {
                                            ...dataRes[data_key],
                                            description: e.target.value ? e.target.value : "",
                                          },
                                        });
                                      }}
                                      value={dataRes[data_key].description}
                                    />
                                  </Form.Group>
                                </td>
                                <td className="bg-white">
                                  <div className="btn-icon-grp-table justify-content-center">
                                    <Button
                                      onClick={() => {
                                        let newJson: any = {};
                                        Object.keys(dataRes).forEach((res_key) => {
                                          if (res_key != data_key) {
                                            newJson = {
                                              ...newJson,
                                              [res_key]: dataRes[res_key],
                                            };
                                          }
                                        });
                                        if (Object.keys(newJson).length) {
                                          setDataRes(newJson);
                                        } else {
                                          setDataRes({});
                                          setCount(0);

                                        }
                                      }}
                                      type="button"
                                      className="btn-icon cancelIcon"
                                    ></Button>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                        <tr>
                          <td colSpan={5} className="bluetext">
                            <div
                              role="button"
                              tabIndex={-1}
                              onClick={() => {
                                setDataRes({
                                  ...dataRes,
                                  [count + 1]: {
                                    itemName: "",
                                    itemType_Term: "Food",
                                    itemCostPerUnit: 0,
                                    taxRatePerc: 0,
                                    orderNo: 0,
                                    description: "",
                                  },
                                });
                                setDataResEr({
                                  ...dataResEr,
                                  [count + 1]: {
                                    itemName: "",
                                  },
                                });
                                setCount(count + 1);
                              }}
                              onKeyDown={() => {
                                setDataRes({
                                  ...dataRes,
                                  [count + 1]: {
                                    itemName: "",
                                    itemType_Term: "Food",
                                    itemCostPerUnit: 0,
                                    taxRatePerc: 0,
                                    orderNo: 0,
                                    description: "",
                                  },
                                });
                                setDataResEr({
                                  ...dataResEr,
                                  [count + 1]: {
                                    itemName: "",
                                  },
                                });
                                setCount(count + 1);
                              }}
                            >
                              <img
                                src={SquarePlus}
                                alt=""
                                width={15}
                                height={15}
                                className="me-1"
                              />
                              Add More
                            </div>
                          </td>

                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <Col>
                    {!submitLoading ? (
                      <Button
                        onClick={(e) => {
                          if (page_rights_Hotel.is_create) {
                            e.preventDefault();
                            handleSaveData();
                          } else {
                            CommonService.unauthorizedAction();
                          }
                        }}
                        className="savebtnclient"
                        type="button"
                      >
                        <i className="fa-solid fa-floppy-disk me-2"></i>Save
                      </Button>
                    ) : (
                      <Button className="btn btn-primary justify-content-center" type="button">
                        <Spinner size="sm" animation="border" variant="light" />
                      </Button>
                    )}
                  </Col>
                </Form>
              </Col>
            </Row>
          </div>
        ) : (
          <PageLoader />
        )}
      </div>

      <div className="information-division pb-2 mt-3">
        <h3 className="inner-header m-0">Beverages</h3>
        {!loading ? (
          <div className="tab-inner-box position-relative">
            <Row>
              <Col>
                <Form>
                  <div className="customtablemetting">
                    <Table responsive bordered className="table-without-space">
                      <thead>
                        <tr className="h-40">
                          <th>Item Name</th>
                          <th>Type</th>
                          <th>Cost Per Unit($)</th>

                          <th>Description</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(dataResBeverage).map((data_key) => {
                          return (
                            <>
                              <tr>
                                <td className="bordernone" >
                                  <Form.Group>
                                    <Form.Control

                                      className={`input-tabal ps-0 pe-0 creatableSpace ${dataResBeverageEr[data_key] &&
                                        dataResBeverageEr[data_key].itemName !== ""
                                        ? "red-border-masking ClassSelectCreatable"
                                        : ""
                                        }`}
                                      type="text"
                                      onChange={(e) => {
                                        setDataResBeverage({
                                          ...dataResBeverage,
                                          [data_key]: {
                                            ...dataResBeverage[data_key],
                                            itemName: e.target.value ? e.target.value : "",
                                          },
                                        });
                                      }}
                                      value={dataResBeverage[data_key].itemName}
                                    />
                                  </Form.Group>
                                </td>
                                <td
                                  style={{ padding: 0, width: "16.6%" }}
                                  className="bordernone"

                                >

                                  <Form.Group>
                                    <Form.Control
                                      className="input-tabal ps-0 pe-0"
                                      type="text"

                                      value={dataResBeverage[data_key].itemType_Term}
                                    />
                                  </Form.Group>
                                </td>
                                <td
                                  className="bordernone"

                                >
                                  <Form.Group>
                                    <Form.Control
                                      className="input-tabal ps-0 pe-0"
                                      type="number"
                                      onWheel={(event: any) => {
                                        event.target.blur();
                                      }}
                                      onKeyDown={(e: any) => {
                                        if (e.keyCode == "38" || e.keyCode == "40") {
                                          e.stopPropagation();
                                          e.preventDefault();
                                        }
                                      }}
                                      onChange={(e) => {
                                        setDataResBeverage({
                                          ...dataResBeverage,
                                          [data_key]: {
                                            ...dataResBeverage[data_key],
                                            itemCostPerUnit: e.target.value ? e.target.value : 0,
                                          },
                                        });
                                      }}
                                      value={
                                        dataResBeverage[data_key].itemCostPerUnit
                                          ? dataResBeverage[data_key].itemCostPerUnit
                                          : ""
                                      }
                                    />
                                  </Form.Group>
                                </td>
                                <td
                                  className="bordernone"
                                >
                                  <Form.Group>
                                    <Form.Control
                                      className="input-tabal ps-0 pe-0"
                                      type="text"
                                      onChange={(e) => {
                                        setDataResBeverage({
                                          ...dataResBeverage,
                                          [data_key]: {
                                            ...dataResBeverage[data_key],
                                            description: e.target.value ? e.target.value : "",
                                          },
                                        });
                                      }}
                                      value={dataResBeverage[data_key].description}
                                    />
                                  </Form.Group>
                                </td>
                                <td

                                  className="bordernone"
                                >
                                  <div className="btn-icon-grp-table justify-content-center">
                                    <Button
                                      onClick={() => {
                                        let newJson: any = {};
                                        Object.keys(dataResBeverage).forEach((res_key) => {
                                          if (res_key != data_key) {
                                            newJson = {
                                              ...newJson,
                                              [res_key]: dataResBeverage[res_key],
                                            };
                                          }
                                        });
                                        if (Object.keys(newJson).length) {
                                          setDataResBeverage(newJson);
                                        } else {
                                          setDataResBeverage({});
                                          setCountBeverage(0);

                                        }
                                      }}
                                      type="button"
                                      className="btn-icon cancelIcon"
                                    ></Button>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                        <tr>
                          <td colSpan={5} className="bluetext">
                            <div
                              role="button"
                              tabIndex={-1}
                              onClick={() => {
                                setDataResBeverage({
                                  ...dataResBeverage,
                                  [countBeverage + 1]: {
                                    itemName: "",
                                    itemType_Term: "Beverage",
                                    itemCostPerUnit: 0,
                                    taxRatePerc: 0,
                                    orderNo: 0,
                                    description: "",
                                  },
                                });
                                setDataResBeverageEr({
                                  ...dataResBeverageEr,
                                  [countBeverage + 1]: {
                                    itemName: "",
                                  },
                                });

                                setCountBeverage(countBeverage + 1);
                              }}
                              onKeyDown={() => {
                                setDataResBeverage({
                                  ...dataResBeverage,
                                  [countBeverage + 1]: {
                                    itemName: "",
                                    itemType_Term: "Beverage",
                                    itemCostPerUnit: 0,
                                    taxRatePerc: 0,
                                    orderNo: 0,
                                    description: "",
                                  },
                                });
                                setDataResBeverageEr({
                                  ...dataResBeverageEr,
                                  [countBeverage + 1]: {
                                    itemName: "",
                                  },
                                });

                                setCountBeverage(countBeverage + 1);
                              }}
                            >
                              <img
                                src={SquarePlus}
                                alt=""
                                width={15}
                                height={15}
                                className="me-1"
                              />
                              Add More
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <Col>
                    {!submitLoadingBeverage ? (
                      <Button
                        onClick={(e) => {
                          if (page_rights_Hotel.is_create) {
                            e.preventDefault();
                            handleSaveBeverageData();
                          } else {
                            CommonService.unauthorizedAction();
                          }
                        }}
                        className="savebtnclient"
                        type="button"
                      >
                        <i className="fa-solid fa-floppy-disk me-2"></i>Save
                      </Button>
                    ) : (
                      <Button className="btn btn-primary justify-content-center" type="button">
                        <Spinner size="sm" animation="border" variant="light" />
                      </Button>
                    )}
                  </Col>
                </Form>
              </Col>
            </Row>
          </div>
        ) : (
          <PageLoader />
        )}
      </div>

      <div className="information-division pb-2 mt-3">
        <h3 className="inner-header m-0">Av Equipments and MISC</h3>
        {!avLoading ? (
          <div className="tab-inner-box position-relative">
            <Row>
              <Col>
                <Form>
                  <div className="customtablemetting">
                    <Table responsive bordered className="table-without-space">
                      <thead>
                        <tr className="h-40">
                          <th className="w-50">Equipment Name</th>
                          <th style={{ width: "20%" }}>Type</th>
                          <th style={{ width: "20%" }}>Cost Per Unit($)</th>

                          <th style={{ width: "10%" }} className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(avDataRes).map((data_key) => {
                          return (
                            <>
                              <tr>
                                <td className="bordernone">
                                  <Form.Group>
                                    <Form.Control

                                      className={`input-tabal ps-0 pe-0 creatableSpace ${avDataEr[data_key] && avDataEr[data_key].avEquipName !== ""
                                        ? "red-border-masking ClassSelectCreatable"
                                        : ""
                                        }`}
                                      type="text"
                                      onChange={(e) => {
                                        setAvDataRes({
                                          ...avDataRes,
                                          [data_key]: {
                                            ...avDataRes[data_key],
                                            avEquipName: e.target.value ? e.target.value : "",
                                          },
                                        });
                                      }}
                                      value={avDataRes[data_key].avEquipName}
                                    />
                                  </Form.Group>
                                </td>

                                <td
                                  style={{ padding: 0, width: "16.6%" }}
                                  className="bordernone"

                                >
                                  <div

                                  >

                                    <Col className="notallowfield fild-input">
                                      <div
                                        className="creatableSelect-properties"
                                      >
                                        <FloatingLabel controlId="floatingInput" label="" className={`${avDataEr[data_key] &&
                                          avDataEr[data_key].avEquipType_Term !== ""
                                          ? "red-border-masking"
                                          : ""
                                          }`} >
                                          <CreatableSelect
                                            isClearable

                                            options={aveTypesList}
                                            onChange={(event: any) => {
                                              setAvDataRes({
                                                ...avDataRes,
                                                [data_key]: {
                                                  ...avDataRes[data_key],
                                                  avEquipType_Term:
                                                    event !== null ? event.label : "",
                                                },
                                              });
                                            }}
                                            placeholder="Select Item"
                                            value={
                                              avDataRes[data_key] &&
                                                avDataRes[data_key].avEquipType_Term !== undefined &&
                                                avDataRes[data_key].avEquipType_Term !== ""
                                                ? {
                                                  label: avDataRes[data_key].avEquipType_Term,
                                                  value: avDataRes[data_key].avEquipType_Term,
                                                }
                                                : {
                                                  label: "Select Item",
                                                  value: "Select Item",
                                                }
                                            }
                                          />
                                        </FloatingLabel>
                                      </div>
                                    </Col>

                                  </div>

                                </td>
                                <td
                                  className="bordernone"

                                >
                                  <Form.Group>
                                    <Form.Control
                                      className="input-tabal ps-0 pe-0"
                                      type="number"
                                      onWheel={(event: any) => {
                                        event.target.blur();
                                      }}
                                      onKeyDown={(e: any) => {
                                        if (e.keyCode == "38" || e.keyCode == "40") {
                                          e.stopPropagation();
                                          e.preventDefault();
                                        }
                                      }}
                                      onChange={(e) => {
                                        setAvDataRes({
                                          ...avDataRes,
                                          [data_key]: {
                                            ...avDataRes[data_key],
                                            avEquipCost: e.target.value ? e.target.value : 0,
                                          },
                                        });
                                      }}
                                      value={
                                        avDataRes[data_key].avEquipCost
                                          ? avDataRes[data_key].avEquipCost
                                          : ""
                                      }
                                    />
                                  </Form.Group>
                                </td>

                                <td

                                  className="bordernone"
                                >
                                  <div className="btn-icon-grp-table justify-content-center">
                                    <Button
                                      onClick={() => {
                                        let newJson: any = {};
                                        Object.keys(avDataRes).forEach((res_key) => {
                                          if (res_key != data_key) {
                                            newJson = {
                                              ...newJson,
                                              [res_key]: avDataRes[res_key],
                                            };
                                          }
                                        });
                                        if (Object.keys(newJson).length) {
                                          setAvDataRes(newJson);
                                        } else {
                                          setAvDataRes(newJson);
                                          setAvCount(0);

                                        }
                                      }}
                                      type="button"
                                      className="btn-icon cancelIcon"
                                    ></Button>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                        <tr>
                          <td colSpan={5} className="bluetext">
                            <div
                              onClick={() => {
                                setAvDataRes({
                                  ...avDataRes,
                                  [avCount + 1]: {
                                    avEquipName: "",
                                    avEquipType_Term: "",
                                    avEquipCost: 0,
                                    taxRatePerc: 0,
                                    orderNO: 0,
                                  },
                                });
                                setAvDataEr({
                                  ...avDataEr,
                                  [avCount + 1]: {
                                    avEquipName: "",
                                    avEquipType_Term: "",
                                  },
                                });
                                setAvCount(avCount + 1);
                              }}
                              role="button"
                              tabIndex={0}
                              onKeyDown={() => {
                                setAvDataRes({
                                  ...avDataRes,
                                  [avCount + 1]: {
                                    avEquipName: "",
                                    avEquipType_Term: "",
                                    avEquipCost: 0,
                                    taxRatePerc: 0,
                                    orderNO: 0,
                                  },
                                });
                                setAvDataEr({
                                  ...avDataEr,
                                  [avCount + 1]: {
                                    avEquipName: "",
                                    avEquipType_Term: "",
                                  },
                                });
                                setAvCount(avCount + 1);
                              }}
                            >
                              <img
                                src={SquarePlus}
                                alt=""
                                width={15}
                                height={15}
                                className="me-1"
                              />
                              Add More
                            </div>
                          </td>

                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <Col>
                    {!avSubmitLoading ? (
                      <Button
                        onClick={(e) => {
                          if (page_rights_Hotel.is_create) {
                            e.preventDefault();
                            handleAvSaveData();
                          } else {
                            CommonService.unauthorizedAction();
                          }
                        }}
                        className="savebtnclient"
                        type="button"
                      >
                        <i className="fa-solid fa-floppy-disk me-2"></i>Save
                      </Button>
                    ) : (
                      <Button className="btn btn-primary justify-content-center" type="button">
                        <Spinner size="sm" animation="border" variant="light" />
                      </Button>
                    )}
                  </Col>
                </Form>
              </Col>
            </Row>
          </div>
        ) : (
          <PageLoader />
        )}
      </div>
    </>
  );
}
