import React, { useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import SquarePlus from "../../assets/Images/Icons/square-plus.svg";
import PageLoader from "../../Component/Loader/PageLoader";
import { getProjectTermByCategory } from "../../Service/UserSetupService";
import CreatableSelect from "react-select/creatable";
const TaxInformationModal = (props: any) => {

  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;
  const [inputList, setInputList]: any = useState([
    { taxTitle: "", rateOrPercentage: "", orderNo: 0, isPercentage: false, TaxRowNo: 0 },
  ]);

  let validationJson = {
    taxTitle: "",
    rateOrPercentage: "",
  };

  const [inputListEr, setInputListEr]: any = useState([validationJson]);

  const [HotelTaxDropdownList, setHotelTaxDropdownList] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [IsvalidState, setIsvalidState] = React.useState({ status: false, Value: "" });

  React.useEffect(() => {

    getHotelTaxDropdown();

    if (
      props.StoreEditRoomMeetingTaxdata.length > 0 &&
      props.StoreEditRoomMeetingTaxdata !== undefined &&
      props.StoreEditRoomMeetingTaxdata !== null
    ) {

      let JsonToIm: any = [];
      let JsonObj: any = {};

      let JsonToImEr: any = [];
      let JsonObjEr: any = {};

      props.StoreEditRoomMeetingTaxdata.map((item) => {
        JsonObj = {
          ...JsonObj,
          taxTitle: item.taxTitle,
          rateOrPercentage: item.rateOrPercentage,
          orderNo: item.orderNo,
          isPercentage: item.isPercentage,
          taxRowNo: props.TaxRowNo,
        };
        JsonToIm.push(JsonObj);

        JsonObjEr = {
          ...JsonObjEr,
          taxTitle: "",
          rateOrPercentage: "",
        };
        JsonToImEr.push(JsonObjEr);
      });
      if (JsonToIm.length > 0) {
        setInputList(JsonToIm);

        setInputListEr(JsonToImEr);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let optionsTax: any = [{
    value: null,
    label: "Select Tax",
  },];
  HotelTaxDropdownList && HotelTaxDropdownList.map((item: any) => {
    optionsTax.push({
      label: item.term,
      value: item.term,
      node: "Creatable"
    });
  });
  const handleAddROOMRowClick = () => {
    setInputList([
      ...inputList,
      { taxTitle: "", rateOrPercentage: "", orderNo: 0, isPercentage: false, taxRowNo: 0 },
    ]);
    setInputListEr([...inputListEr, { taxTitle: "", rateOrPercentage: "" }]);
  };
  const handleInputRoomChange = (event, index) => {

    if (event === null) {
      const list: any = [...inputList];

      list[index]["taxTitle"] = "";
    }

    else if (event.node === "Creatable") {

      const list: any = [...inputList];

      let value = event.value.trim();
      list[index]["taxTitle"] = value;
    }
    else if (event.__isNew__) {
      const list: any = [...inputList];
      let value = event.value.trim();
      list[index]["taxTitle"] = value;
    }
    else {

      if (event.value !== null) {
        let { name, value }: any = event.target;
        const list = [...inputList];

        value = value.trim();
        list[index][name] = value;

        if (value) {
          let pattern: any = /^\d+(\.)?\d*%?$/;
          if (pattern.test(value)) {
            if (value !== null && value !== "" && value !== undefined) {
              if (value.includes("%")) {
                list[index]["isPercentage"] = true;
                list[index]["rateOrPercentage"] = value ? value : "";
              } else {
                list[index]["isPercentage"] = false;
                list[index]["rateOrPercentage"] = value ? value : "";
              }
            }
            list[index]["taxRowNo"] = props.TaxRowNo;
            list[index]["orderNo"] = index;
            setIsvalidState({ status: false, Value: "" });
            setInputList(list);
          } else {
            setIsvalidState({ status: true, Value: value });
            list[index][name] = "";
            setInputList(list);
          }
        } else {
          list[index][name] = value;
          setInputList(list);
        }
      }
    }

  };

  function CheckKeyEvent(event) {
    if (
      (event.charCode >= 48 && event.charCode <= 57) ||
      event.charCode === 46 ||
      event.charCode === 37
    ) {
      if (event.charCode === 46) {
        if (event.target.value.indexOf(".") >= 0) {
          event.preventDefault();
        }
      }

      if (event.charCode === 37) {
        if (event.target.value.indexOf("%") >= 0) {
          event.preventDefault();
        }
      }
    } else {
      event.preventDefault();
    }
  }
  const validateForm = () => {
    let is_validate = true;
    let newInputEr = [...inputListEr];
    inputList.forEach((itm, i) => {
      Object.keys(validationJson).forEach((key) => {
        if ((!itm[key] && parseInt(itm[key]) !== 0) || itm[key] === "") {
          is_validate = false;
          newInputEr[i][key] = "This field is required.";
        } else {
          newInputEr[i][key] = "";
        }
      });
    });
    setInputListEr(newInputEr);
    return is_validate;
  };

  function GetValue() {
    if (validateForm()) {

      props.storeRoomMeetingTax(props.TaxRowNo, inputList);
      setInputList([
        ...inputList,
        { taxTitle: "", rateOrPercentage: "", orderNo: 0, isPercentage: false, taxRowNo: 0 },
      ]);

      setInputListEr([...inputListEr, { taxTitle: "", rateOrPercentage: "" }]);
      props.onHide(false);
    }
  }
  function RemoveTaxRow(id: any) {

    const list = [...inputList];
    const listER = [...inputListEr];

    if (id !== null && id !== "" && id !== undefined) {
      list.splice(id, 1);
      listER.splice(id, 1);
    }

    setInputList(list);
    setInputListEr(listER);

  }

  async function getHotelTaxDropdown() {
    let inputs = {
      category: "Hotel Tax",
      clientID: clientID,
    };
    setLoading(true);
    const resDropdown: any = await getProjectTermByCategory(inputs, Token);
    setLoading(false);

    if (resDropdown.data.success) {
      if (resDropdown.data !== null && resDropdown.data !== undefined) {
        if (resDropdown.data.data !== undefined && resDropdown.data.data !== null) {

          setHotelTaxDropdownList(resDropdown.data.data.projectTermL);
        }
      }
    }
  }

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-50w"
        className="tax-information-popup quickAdd-popup clientpoupadd"
        scrollable={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"> {props.RoomNamePass && props.RoomNamePass + " -"} Tax Information </Modal.Title>
        </Modal.Header>
        {/* <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
               {props.RoomNamePass && props.RoomNamePass + " -"} Tax Information
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="tax-information customtablemetting">
            {!loading ? (
              <Table className="table-without-space mb-0" responsive bordered>
                <thead>
                  <tr>
                    <th className="settitle w-50">Type of Tax</th>
                    <th className="settitle w-50">Tax (% or $)</th>
                    <th className="settitle w-50">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {inputList.map((x, i) => {
                    return (
                      <>
                        <tr>
                          <td className=" bordernone fild-input">

                            <div
                              className={`${inputListEr[i].taxTitle !== "" && "red-border-masking mt-1 mb-1 w-100"
                                }`}
                            >
                              <CreatableSelect
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                  }),
                                }}
                                placeholder={"Select Tax"}
                                isClearable
                                options={optionsTax}
                                // onCreateOption={(event) => handleInputRoomCreatable(event, i)}
                                onChange={(event: any) => handleInputRoomChange(event, i)}
                                className='ClassSelectCreatable'
                                defaultValue={
                                  x.taxTitle !== ""
                                    ? {
                                      label: x.taxTitle,
                                      value: x.taxTitle,
                                    }
                                    : {
                                      label: "Select Tax",
                                      value: "Select Tax",
                                    }
                                }
                              />

                              {/* <Form.Select
                                aria-label="Default select example"
                                name="taxTitle"
                                onChange={(e) => handleInputRoomChange(e, i)}
                              >
                                <option
                                  selected={x.taxTitle === "" || !x.taxTitle ? true : false}
                                  value=""
                                >
                                  Select Service Tax
                                </option>
                                {HotelTaxDropdownList && HotelTaxDropdownList.map((item: any, index) => (
                                  <option
                                    selected={item.term === x.taxTitle ? true : false}
                                    key={index}
                                    value={item.term}
                                  >
                                    {item.term}
                                  </option>
                                ))}
                              </Form.Select> */}
                            </div>
                            {/* <small style={{ color: "#dc3545" }}>
                              {inputListEr[i].taxTitle !== "" ? inputListEr[i].taxTitle : ""}
                            </small> */}
                          </td>
                          <td className="bordernone">
                            <Form.Group>
                              <Form.Control
                                className={`multiselect mt-1 mb-1 ${inputListEr[i].rateOrPercentage !== "" && "is-invalid"
                                  }`}
                                type="text"
                                value={x.rateOrPercentage}
                                maxLength={256}
                                name="rateOrPercentage"
                                onChange={(e) => handleInputRoomChange(e, i)}
                                onKeyPress={(e) => CheckKeyEvent(e)}
                              />
                            </Form.Group>
                            {
                              IsvalidState.status === true && (
                                <Form.Control.Feedback type="invalid">
                                  {"Invalid Input!! (" + IsvalidState.Value + ")"}
                                </Form.Control.Feedback>
                              )
                              // : inputListEr[i].rateOrPercentage !== "" && (
                              //   <Form.Control.Feedback type="invalid">
                              //     {inputListEr[i].rateOrPercentage}
                              //   </Form.Control.Feedback>
                              // )
                            }
                          </td>
                          <td>
                            <span className="btn-icon-grp-table justify-content-end border-0">
                              <Button
                                className="btn-icon cancelIcon"
                                onClick={() => RemoveTaxRow(i)}
                              ></Button>
                            </span>
                          </td>
                        </tr>

                      </>
                    );
                  })}
                  {/* {inputList.length - 1 === i && ( */}
                  <tr>
                    <td colSpan={3} className="bluetext">
                      <div
                        role="button"
                        tabIndex={-1}
                        onClick={handleAddROOMRowClick}
                        onKeyDown={handleAddROOMRowClick}
                      >
                        <img
                          src={SquarePlus}
                          alt=""
                          width={15}
                          height={15}
                          className="me-1"
                        />
                        Add More
                      </div>
                    </td>
                  </tr>
                  {/* )} */}
                </tbody>
              </Table>
            ) : (
              <PageLoader />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={props.onHide}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button> */}
          <Button className="savebtnclient" onClick={() => GetValue()}>
            <i className="fa-solid fa-floppy-disk me-2"></i>Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default TaxInformationModal;