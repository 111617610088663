import "@szhsin/react-menu/dist/index.css";
import React from "react";
import { Form } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getClientListDropdown } from "../Redux/actions";
import { getClientList } from "../Service/ClientService";
import ChangepasswordModal from "../Views/Model/ChangepasswordModal";
import logo from "../assets/Images/Icons/sidebarIcons/crmLogo.svg";
import { GetGlobalSearch } from "../Service/DashboardService";
import SendEmailToClients from "../Views/Model/SendEmailToClients";
import PageLoader from "../Component/Loader/PageLoader";
import { Cookies } from "react-cookie-consent";
import MailInbox from "../Views/Model/MailInbox";
function DefaultHeader() {

    const [modalShowpassword, setModalpasswordShow] = React.useState(false);
    const [showSendMailToClientsModal, setShowSendMailToClientsModal] = React.useState(false)
    const [loading, setLoading] = React.useState(false);

    const dataSelector: any = useSelector((state: any) => state.session);
    const Token = dataSelector.access_Token;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [ShowInbox, setShowInbox] = React.useState(false)

    React.useEffect(() => {
        getUserClientListData(Token);
    }, []);
    async function getUserClientListData(value) {
        let resUserList: any = await getClientList(value);

        if (resUserList.data !== null) {
            if (resUserList.data.success) {
                if (resUserList.data !== undefined) {
                    dispatch(getClientListDropdown(resUserList.data.data.clients));
                }
            }
        }
    }

    function logout() {
        localStorage.clear();
        window.location.reload();
        Cookies.remove("SystemSettingDate");
        Cookies.remove("SystemSettingTime");
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
    }

    let timeout: any = null;

    async function executeListing(e: any) {
        let res: any = [];
        let input: any = {
            searchText: e
        }
        setLoading(true)
        res = await GetGlobalSearch(input, Token)


        if (res.data !== null && res.data !== undefined && res.data !== "") {
            if (res.data.data !== null && res.data.data !== undefined && res.data.data !== "") {
                navigate('/search', { state: { result: res.data.data } });
                setLoading(false)
            }
        }
        if (!e) {
            navigate('/');
            setLoading(false)
        }
        setLoading(false)
    }
    async function SearchGlobal(e: any) {
        setLoading(true)
        clearTimeout(timeout);
        timeout = setTimeout(function () {
            if (e.keyCode != 13) {
                executeListing(e.target.value);
            }
        }, 1000);
    }
    function openNav() {
        var element: any = document.getElementById("mySidebar");
        element.classList.toggle("mystyle");

    }
    return (
        <>
            <div className="header-top-new-block 123456789">
                <div className="main-header">
                    <div className="logo">
                        <img src={logo} alt="" />
                    </div>
                    <div className="search-bar-main w-100">
                        <div className="search-bar">
                            <Form action="javascript;" className="d-flex ps-4">
                                <input
                                    className="form-control searchInput"
                                    type="text"
                                    placeholder="Search"
                                    aria-label="Search"
                                    onChange={(e) => SearchGlobal(e)}
                                />
                                <button className="btn search-btn" type="submit">
                                    <i className="fa-regular fa-magnifying-glass"></i>
                                </button>
                            </Form>
                        </div>
                        <div className="right-header-icon d-flex align-items-center">
                            {/* Comment Mail Inbox Model : By Satish Sir. */}
                            {/* <div
                                role="button"
                                tabIndex={-1}
                                onClick={() => { setShowInbox(true) }}
                                className="cursor-pointer me-2">
                                <i className="fa-light fa-mailbox me-2"></i>
                            </div> */}
                            <div
                                role="button"
                                tabIndex={-1}
                                onKeyDown={() => { setShowSendMailToClientsModal(true) }}
                                onClick={() => { setShowSendMailToClientsModal(true) }} className="cursor-pointer me-2">
                                <i className="fa-regular fa-envelope"></i>
                            </div>
                            <DropdownButton
                                align="end"
                                title={<div><i className="fa-light me-2  fa-circle-user"></i> <i className="fa-light fa-chevron-down doptdoa"></i></div>}
                                id="dropdown-menu-align-end"
                                className="headrdrop"
                            >
                                <Dropdown.Item eventKey="1" onClick={() => setModalpasswordShow(true)}><i className="fa-light fa-key-skeleton me-2"></i>Change Password</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item eventKey="2" onClick={() => logout()} onKeyDown={() => logout()}><i className="fa-light fa-arrow-right-from-bracket me-2"></i>Logout</Dropdown.Item>

                            </DropdownButton>
                            <button className="openbtn" onClick={openNav}><i className="fa-regular fa-bars"></i></button>
                        </div>
                    </div>

                </div>
            </div>

            {loading ? (
                <div className="backgroug-blur-loader-main">
                    <PageLoader />
                </div>
            ) : (
                ""
            )}

            {showSendMailToClientsModal && <SendEmailToClients
                setModalShow={setShowSendMailToClientsModal}
                modalShow={showSendMailToClientsModal}
            />}
            <ChangepasswordModal
                Token={Token}
                dataSelector={dataSelector}
                show={modalShowpassword}
                onHide={() => setModalpasswordShow(false)}
            />

            {ShowInbox && <MailInbox
                setModalShow={setShowInbox}
                modalShow={ShowInbox}
            />}
        </>
    );
}

export default DefaultHeader;
